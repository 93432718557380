import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Select, MenuItem,
  FormControl,
  InputLabel,
  Autocomplete
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../../../store/actions/authAction';
import { getAllUserGroup } from "../../../store/actions/authUserGroupActions"
const UserForm = () => {
  const dispatch = useDispatch(); // Access dispatch function from Redux
  const { authUserGroup } = useSelector((s) => s.authUserGroup);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    group_id: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReset = () => {
    setFormData({
      name: '',
      email: '',
      password: '',
      group_id: ''
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch the signup action with form data
    dispatch(signup(formData));
    handleReset()
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    dispatch(getAllUserGroup({ data: {} })); // Ensure this is being called
  }, []);
  return (
    <Container maxWidth="xs" sx={{ marginTop: 8 }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: 3,
          padding: 3,
          borderRadius: 2,
          backgroundColor: 'white',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Create User Form
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          placeholder='Name'
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoComplete="off"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          name="email"
          placeholder='Email'
          value={formData.email}
          onChange={handleChange}
          autoComplete="off"
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          name="password"
          placeholder='Password'
          type="password"
          value={formData.password}
          onChange={handleChange}
          autoComplete="off"
        />
        <Autocomplete
      options={authUserGroup} 
      getOptionLabel={(option) => option.group_name || ''}
      value={authUserGroup.find((group) => group.id === formData.group_id) || null}
      onChange={(event, newValue) => {
        handleChange({
          target: {
            name: 'group_id',
            value: newValue ? newValue.id : '',
          },
        });
      }}
      renderInput={(params) => (
        <TextField sx={{height:'40px',width:'350px'}}
          {...params}
          placeholder="Select User Group"
          variant="outlined"
          fullWidth
          margin="normal"
        />
      )}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      noOptionsText="No groups available"
    />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: 2 }}>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleReset}>
            Reset
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default UserForm;
