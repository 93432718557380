import { createAsyncThunk } from "@reduxjs/toolkit";
import { userService } from "../services/userService";  
import { setAlert, setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";


export const getUser = createAsyncThunk(
    "user/userAgencies",
    async (data, { rejectWithValue, dispatch }) => {
      try {
       
        dispatch(setLoading("Fetching Agencies..."));
        const response = await userService.getUserdatas(data);
        
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  );


  export const getuserinfo = createAsyncThunk("user/userinfo",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Fetching Agency..."));
        const response = await userService.getUserinfo(param);
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const passwordreset = createAsyncThunk("user/passwordreset",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Loading..."));
        const response = await userService.passwordreset(param);
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const updateEmail = createAsyncThunk("user/updateEmail",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Updating Email..."));
        const response = await userService.updateEmail(param).then(()=> {
          window.location.reload()
        });
        dispatch(setLoading(null));
        return response?.data;
        
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const otpgenerate = createAsyncThunk("user/otpverify",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Generating Mail..."));
        const response = await userService.otpgenerate(param).then(()=> {
        });
        dispatch(setLoading(null));
        return response?.data;
        
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const resendOtp = createAsyncThunk("user/otpverify",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Generating Mail..."));
        const response = await userService.resendOtp(param).then(()=> {
        });
        dispatch(setLoading(null));
        return response?.data;
        
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const otpverify = createAsyncThunk("user/otpverify",
    async ({cb=()=>{}, ...param}, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Verifing OTP.."));
        const response = await userService.otpverify(param).then((data)=>{
          dispatch(setAlert({message:data?.data?.message,type:"success"}))
         window.location.reload()
         cb()
        }).catch((err)=>{
          dispatch(setAlert({message:err?.response?.data?.error?.name,type:"error"}))
        })
        dispatch(setLoading(null));
        
        return response?.data;
        
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )


  export const mailverify = createAsyncThunk("user/passwordreset",
    async ({cb=()=>{}, ...param}, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Loading..."));
        const response = await userService.mailverify(param);
        if(response?.data?.message?.data?.mailverify !== null && response?.message?.data?.singupverify !== null){
          cb()
        } 
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const deleteUserById = createAsyncThunk("userdelete/byId",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Fetching Agency..."));
        const response = await userService.deleteUserById(param)
        dispatch(setLoading(null));
        // dispatch(setAlert(response?.message))
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        dispatch(setAlert({type:null,message:err?.response?.data?.message}))
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )