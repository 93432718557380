import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Grid, IconButton, Tooltip,styled } from '@mui/material';
import moment from 'moment';
import { getpagessupport } from '../../store/actions/flozySupportAction';
import { getInvite } from '../../store/actions/inviteAction';
import { useNavigate } from 'react-router-dom';
import Style from './detailedStyle'; 
import { highlightText } from '../../utils/appHelper'; 
import { InvoiceIcon } from '../../assets/svg';
import { CrossArrow } from '../../assets/svg';
import { CopyIcon } from '../../assets/svg';
import CheckIcon from '@mui/icons-material/Check';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getLicenseAsync } from '../../store/actions/licenseAction';
import { Avatar } from '../../assets/svg';
const CopyButton = ({ text }) => {
  const [copied, setCopied] = React.useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); 
  };

  return (
    <CopyToClipboard text={text} onCopy={handleCopy}>
      <Tooltip title={copied ? "Copied!" : "Copy"} arrow>
        <IconButton className={copied ? "copied-icon" : "copy-icon"}>
          {copied ? <CheckIcon /> : <CopyIcon height="24px" width="24px" />}
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
};

const DetailedPage = ({ props}) => {
  const { agencies = { data: [] }, invoices = { data: [] }, users = { data: [] }, globalSearch = { data: [] } } = useSelector((state) => state.search);
  const { list = { results: [] } } = useSelector((state) => state.support);
  const { list: invitelist = { results: [] } } = useSelector((state) => state.Invite);
  const {  list:licenselist } = useSelector((state) => state.license);
  const { list:collablist } = useSelector((state) => state.collab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes= Style()
  useEffect(() => {
    if (globalSearch.data.searchText) {
      dispatch(getpagessupport({ search: globalSearch.data.searchText }));
      dispatch(getInvite({ search: globalSearch.data.searchText }));
      dispatch(getLicenseAsync({search: globalSearch.data.searchText}))
    }
  }, [dispatch, globalSearch.data.searchText]);

  const agencyOptions = agencies.data.slice(0, 4).map(option => ({
    id: option.id,
    agency_name: option.agency_name,
    agency_logo: option.agency_logo,
    agency_email: option.agency_email,
    created_on: option.created_on
  }));

  const invoiceOptions = invoices.data.slice(0, 4).map(option => ({
    id: option.id,
    invoice_number: option.invoice_number,
    invoice_logo: option.invoice_logo,
    customer_email: option.customer_email,
    customer_name: option.customer_name,
    due_date: option.due_date,
    invoice_currency: option.invoice_currency,
    created_on: option.created_on
  }));

  const userOptions = users.data.slice(0, 4).map(option => ({
    email: option.email,
    first_name: option.first_name,
    last_name: option.last_name,
    username: option.username,
    created_on: option.created_on
  }));

  const supportOptions = list.results.slice(0, 4).map(option => ({
    agency_name: option.agency_name,
    user_name: option.user_name,
    ticket_no: option.ticket_no,
    invitation_date: option.created_on,
    status: option.status || 'Pending'
  }));

  const invitationOptions = invitelist.results.slice(0, 4).map(option => ({
    agency_name: option.agency_name,
    invite_email: option.invite_email,
    invited_code: option.invite_code,
    status: option.invite_status,
    created_on: option.created_on
  }));
  const licenseOptions = licenselist.results.slice(0, 4).map(option => ({
    plan_name: option.plan_name,
    status: option.status,
    currency:option.currency,
    price: option.price,
    validity: option.validity,
    plan_type: option.plan_type
  }));
  const collabOptions =  collablist?.results?.slice(0,4).map(option => ({
    customer_name: option.customer_name,
    customer_email: option.customer_email,
    collab_plan: option.collab_plan,
  }))
  const gotoAgencyList = () => {
    navigate(`/agencies/list?search=${encodeURIComponent(globalSearch.data.searchText)}`);
  };

  const gotoUserList = () => {
    navigate(`/agencies/users?search=${encodeURIComponent(globalSearch.data.searchText)}`);
  };

  const gotoInvitation = () => {
    navigate(`/invite?search=${encodeURIComponent(globalSearch.data.searchText)}`);
  };
  const gotoSupport = () => {
    navigate(`/pagesupport?search=${encodeURIComponent(globalSearch.data.searchText)}`);
  };
  const gotoLicense = () => {
    navigate(`/products/licence?search=${encodeURIComponent(globalSearch.data.searchText)}`);
  };
  const gotoCollab = () => {
    navigate(`/collab?search=${encodeURIComponent(globalSearch.data.searchText)}`)
  };
  return (
    <Grid container spacing={3} className={classes}>
      {invoiceOptions.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={classes.tableContainer} >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5} style={{ padding: '8px 16px', borderBottom: 'none' }}>
                    <Grid container alignItems="center">
                      <Typography variant="body1" sx={classes.resultsTextStyle}>
                        Showing results for: 
                        <Typography variant="body1" component="span" sx={classes.searchTextStyle}>
                          &quot;{globalSearch.data.searchText}&quot;
                        </Typography>
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={classes.headerStyle}>Invoice</Typography>
                      <Button variant="outlined" color="primary" sx={classes.viewAllButton} >View All
                        <CrossArrow sx={classes.crossArrowIcon} />
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={classes.tablehead}>Invoice Number</TableCell>
                  <TableCell sx={classes.tablehead}>Customer Name</TableCell>
                  <TableCell sx={classes.tablehead}>Due Date</TableCell>
                  <TableCell sx={classes.tablehead}>Currency</TableCell>
                  <TableCell sx={classes.tablehead}>Customer Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceOptions.map((option) => (
                  <TableRow key={option.id}>
                    <TableCell sx={classes.tableCellText}>
                      <Grid container alignItems="center" sx={classes.icon} >
                        <InvoiceIcon sx={classes.invoiceIcon}  />
                        <Typography sx={classes.optionText}>
                          {highlightText(option.invoice_number, globalSearch.data.searchText)}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell sx={classes.tableCellText} >{highlightText(option.customer_name, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>
                      {highlightText(moment(option.due_date).format('MMM D, YYYY'), globalSearch.data.searchText)}
                    </TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.invoice_currency, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.customer_email, globalSearch.data.searchText)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      {agencyOptions.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={classes.headerStyle}>Agency</Typography>
                      <Button variant="outlined" color="primary" sx={classes.viewAllButton}onClick={gotoAgencyList}>View All
                        <CrossArrow sx={classes.crossArrowIcon}/>
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  sx={classes.tablehead}>Agency Logo</TableCell>
                  <TableCell  sx={classes.tablehead}>Agency Name</TableCell>
                  <TableCell sx={classes.tablehead}>Agency Email</TableCell>
                  <TableCell sx={classes.tablehead}>Created On</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agencyOptions.map((option) => (
                  <TableRow key={option.id}>
                    <TableCell sx={classes.tableCellText}><Avatar src={option.agency_logo} alt="Agency Logo" /></TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.agency_name, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.agency_email, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>
                      {highlightText(moment(option.created_on).format('MMM D, YYYY'), globalSearch.data.searchText)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      {userOptions.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={classes.headerStyle}>User</Typography>
                      <Button variant="outlined" color="primary" sx={classes.viewAllButton}  onClick={gotoUserList}>View All
                        <CrossArrow sx={classes.crossArrowIcon} />
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={classes.tablehead}>Email</TableCell>
                  <TableCell sx={classes.tablehead}>Username</TableCell>
                  <TableCell sx={classes.tablehead}>Created On</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userOptions.map((option) => (
                  <TableRow key={option.email}>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.email, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.username, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>
                      {highlightText(moment(option.created_on).format('MMM D, YYYY'), globalSearch.data.searchText)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
       {collabOptions?.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={4}>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={classes.headerStyle}>Collab</Typography>
                      <Button variant="outlined" color="primary" sx={classes.viewAllButton}onClick={gotoCollab}>View All
                        <CrossArrow sx={classes.crossArrowIcon}/>
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell  sx={classes.tablehead}>Name</TableCell>
                  <TableCell  sx={classes.tablehead}>Email</TableCell>
                  <TableCell sx={classes.tablehead}>Plan</TableCell>
                  <TableCell sx={classes.tablehead}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agencyOptions.map((option) => (
                  <TableRow key={option.id}>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.customer_name, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.customer_email, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.collab_plan, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>
                      {highlightText(moment(option.created_on).format('MMM D, YYYY'), globalSearch.data.searchText)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      {supportOptions.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={classes.headerStyle} >Flozy support</Typography>
                      <Button variant="outlined" color="primary" sx={classes.viewAllButton} onClick={gotoSupport}>View All
                        <CrossArrow sx={classes.crossArrowIcon}/>
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={classes.tablehead}>Agency Name</TableCell>
                  <TableCell sx={classes.tablehead}>User Name</TableCell>
                  <TableCell sx={classes.tablehead}>Ticket No</TableCell>
                  <TableCell sx={classes.tablehead}>Invitation Date</TableCell>
                  <TableCell sx={classes.tablehead}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supportOptions.map((option) => (
                  <TableRow key={option.ticket_no}>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.agency_name, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.user_name, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.ticket_no, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>
                      {highlightText(moment(option.invitation_date).format('MMM D, YYYY'), globalSearch.data.searchText)}
                    </TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.status, globalSearch.data.searchText)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

      {invitationOptions.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={classes.headerStyle}>Invitations</Typography>
                      <Button variant="outlined" color="primary" sx={classes.viewAllButton} onClick={gotoInvitation}>
                        View All
                        <CrossArrow sx={classes.crossArrowIcon}/>
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={classes.tablehead}>Agency Name</TableCell>
                  <TableCell sx={classes.tablehead}>Email</TableCell>
                  <TableCell sx={classes.tablehead}>Code</TableCell>
                  <TableCell sx={classes.tablehead}>Status</TableCell>
                  <TableCell sx={classes.tablehead}>Invitation Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invitationOptions.map((option) => (
                  <TableRow key={option.invited_code}>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.agency_name, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.invite_email, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>
                      <CopyButton text={option.invited_code} />
                    </TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.status, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>
                      {highlightText(moment(option.created_on).format('MMM D, YYYY'), globalSearch.data.searchText)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}

{licenseOptions.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5}>
                    <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" sx={classes.headerStyle}>License</Typography>
                      <Button variant="outlined" color="primary" sx={classes.viewAllButton} onClick={gotoLicense}>
                        View All
                        <CrossArrow sx={classes.crossArrowIcon} />
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={classes.tablehead}>Plan Name</TableCell>
                  <TableCell sx={classes.tablehead}>License Status</TableCell>
                  <TableCell sx={classes.tablehead}>License Price</TableCell>
                  <TableCell sx={classes.tablehead}>Validity</TableCell>
                  <TableCell sx={classes.tablehead}>Plan Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {licenseOptions.map((option) => (
                  <TableRow key={option.invited_code}>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.plan_name, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.status, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>
                    <Typography variant="body2" component="span" sx={classes.dollarSign}>
                      {highlightText('$', globalSearch.data.searchText)}
                    </Typography>
                      {highlightText(option.price, globalSearch.data.searchText)}
                    </TableCell>
                    <TableCell sx={classes.tableCellText} >{highlightText(option.validity, globalSearch.data.searchText)}</TableCell>
                    <TableCell sx={classes.tableCellText}>{highlightText(option.plan_type, globalSearch.data.searchText)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        )}
    </Grid>
  );
};

export default styled(DetailedPage)(Style);
