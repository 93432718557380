import { createAsyncThunk } from "@reduxjs/toolkit";
import { agenciesService } from "../services/agenciesService";
import { setAlert, setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";

export const getAgencies = createAsyncThunk(
  "agencies/getAgencies",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      
      dispatch(setLoading("Fetching Agencies..."));
      const response = await agenciesService.getAgencies(data)
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
);


export const getAgenciesById = createAsyncThunk("agencies/byId",
  async (param, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Fetching Agency..."));
      const response = await agenciesService.getAgencyById(param)
      dispatch(setLoading(null));
      // dispatch(setAlert(response?.message))
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      dispatch(setAlert({type:null,message:err?.response?.data?.message}))
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const getAgencyDeatil = createAsyncThunk("agencies/agencyClient",
  async (param, { rejectWithValue, dispatch }) => {
    const { id, detail, stripe_id,subscription_id,data } = param
   
    try {
      dispatch(setLoading("Fetching Agency..."));
      const response = await agenciesService.getAgencyDeatil(id, detail,stripe_id,subscription_id, data);
  
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)
export const getAllAgencyDetailsAsync = createAsyncThunk("agencies/getAllAgencyList",
  async ( param , { rejectWithValue, dispatch }) => {
    
    try {
      dispatch(setLoading("Fetching Agency..."));
      const response = await agenciesService.getAllAgencyDetail(param);
  
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const agencyEmailupdate = createAsyncThunk("agencies/Emilupdate",
  async ( {cb=()=>{}, ...param} , { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Updating Email..."));
      const response = await agenciesService.updateagencyEmail(param).then((data)=>{
      window.location.reload()
       //cb()
      })
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const existingmailVerify = createAsyncThunk("agencies/mailVerify",
  async ( {cb=()=>{},cb2=()=>{}, ...param} , { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Fetching Agency..."));
      const response = await agenciesService.existingmailVerify(param)
      if(response?.data?.verifymail?.data?.mailcheck !== null) {
        cb()
      }
      else{
        cb2()
      }
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const otpgenerate = createAsyncThunk("agencies/otpgenerate",
  async ({cb=()=>{}, ...param}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Generating OTP..."));
      const response = await agenciesService.otpgenerate(param)
      dispatch(setLoading(null));
      cb()
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const otpverify = createAsyncThunk("agencies/otpverify",
  async ({cb=()=>{}, ...param}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Verifying OTP..."));
      const response = await agenciesService.otpverify(param).then((data)=>{
        dispatch(setAlert({message:data?.data?.otpverify?.message,type:"success"}))
        window.location.reload()
        cb()
       }).catch((err)=>{
         dispatch(setAlert({message:err?.response?.data?.error?.name,type:"error"}))
       })
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)


export const resendotp = createAsyncThunk("agencies/resendotp",
  async ({cb=()=>{}, ...param}, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Generating OTP..."));
      const response = await agenciesService.resendotp(param)
      dispatch(setLoading(null));
      cb()
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const updateStripe = createAsyncThunk("agencies/updatestripe",
  async (param, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Updating Datas..."));
      const response = await agenciesService.updateStripe(param)
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)

export const cloneSpecialFlow = createAsyncThunk("agencies/specialflow",
  async (param, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Six Week Fast Track ..."));
      const response = await agenciesService.cloneSpecialFlow(param)
      dispatch(setLoading(null));
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  }
)
export const deleteAgenciesById = createAsyncThunk("agenciesdelete/byId",
  async (param, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoading("Fetching Agency..."));
      const response = await agenciesService.deleteAgencyById(param)
      dispatch(setLoading(null));
      // dispatch(setAlert(response?.message))
      return response?.data;
    } catch (err) {
      dispatch(setLoading(null));
      dispatch(setAlert({type:null,message:err?.response?.data?.message}))
      parseErrorResponses(err?.response || err, dispatch);
      return rejectWithValue(err);
    }
  })