import theme from "../../../theme"

const Style =()=>({
    ".agenciDetailCard": {
        padding: "32px",
        borderRadius: '12px',
        background: theme.palette.containers.card,
        "& .agenciName": {
          display: "flex",
          alignItems: "center",
          "& .MuiAvatar-root": {
            width: "60px",
            height: "60px",
          },
        },
        "& .chipItem": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "12px",
          marginBottom: '12px',
          "&.agenciStatusActive": {
            background: `${theme.palette.colors.darkGreen}20`,
            height: "24px",
            padding: "6px 16px",
          },
          "&.agenciInactive": {
            background: `${theme.palette.colors.organe}90`,
            height: "24px",
            padding: "6px 16px",
          },
          "&.agenciTrail": {
            background: `${theme.palette.colors.blue}20`,
            height: "24px",
            padding: "6px 16px",
          },
          "&.agenciPlan": {
            background: `${theme.palette.colors.blue}10`,
            height: "56px",
            padding: "16px 16px",
          },
          "&.agenciDate": {
            background: `${theme.palette.colors.red}10`,
          },
        },
        '& .changePlanBtn': {
          padding: '6px 24px',
          borderRadius: '50px',
          background: `${theme.palette.primary.main}10`,
          border: `1px solid ${theme.palette.primary.main}`,
          fontSize: '10px',
          color: `${theme.palette.primary.main}`,
          height: '28px'
        },
        "& .agenciTags": {
          marginTop: "24px",
          "& .tagesChip": {
            padding: "6px 16px",
            height: "40px",
            borderRadius: "40px",
            background: `${theme.palette.colors.blue}20`,
            display: "flex",
            alignItems: "center",
          },
        },
        "& .agenciContact": {
          marginTop: "0px",
          "& .leftItem": {
            height: "40px",
            width: "40px",
            borderRadius: "20px",                         
            border: "1px solid #EBEAED",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          "& .email_contaner" :{
                display:"flex",
          justifyContent:"space-between"
          }
        },
    
    
        '& .usersCountChip': {
          marginTop: '24px',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: '48px',
          borderRadius: '54px',
          padding: '8px 24px 8px 8px',
          background: theme.palette.containers.greyBg2,
          '& .UsersIcon': {
            height: '32px',
            width: '32px',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: theme.palette.containers.greyBg1,
            borderRadius: '30px'
          }
        },
        '& .transactionCard': {
          background: theme.palette.containers.greyBg3,
          padding: '10px 16px',
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: '24px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '30px'
          }
        }
      },
    '.planCard': {
    borderRadius: '12px',
    background: '#FFF',
    padding: '16px',
    position: 'relative',
    '& .PlanTag': {
      position: 'absolute',
      top: '0',
      right: '0'
    }
  },
  ".planDetails":{
    marginTop: "32px",
    borderRadius: '12px',
    padding:"32px",
    background: theme.palette.containers.card,
  },
  ".header": {
    fontWeight: 700,
    fontSize: "18px",
    color: "#2C3659"
},
".textForm":{
    fontWeight:400,
    fontSize:"16px",
    color:"#8E8E8E"
},
".pricing": {
  display: "flex",
  justifyContent: "space-between",
},
".addproducts":{
  " .planlistnames":{
    height: '40px', 
    width:'200px',        
     '& .MuiInputBase-root': {
      height: '30px',       
    },
  },
},
".coupons": {
  display: "flex",
  flexDirection: "column"
},
".couponbutton":{
padding:"0px",
justifyContent:"flex-start"
},
".taxes":{
  display:"flex",
  flexDirection:"column",
  width:"fit-content",
},
".textforms": {
    color: "#0F172A",
    fontFamily: "Inter, sans-serif",
    fontWeight: 500,
    fontSize: "14px",
  },
  "textbox":{

  },
  datePicker: {
    '& .MuiOutlinedInput-root':{
      height: "30px !important"
    }
  }

})
export default Style