const Style = ({ theme }) => ({
    ".campaignItem": {
        padding: '16px',
        borderRadius: '8px',
        '&.active, &:hover': {
            outline: '1px solid #2563EB',
            boxShadow: '0px 4px 12px #0000000F'
        },
        '.statusChip': {
            fontSize: '12px',
            padding: '0.5px 8px',
            borderRadius: '30px',
            '&.active': {
                background: theme.palette.chips.active.bg,
                color: theme.palette.chips.active.color
            },
            '&.draft': {
                background: theme.palette.chips.draft.bg,
                color: theme.palette.chips.draft.color
            }
        },
        '.assetItem': {
            border: '1px solid #94A3B8',
            borderRadius: '7px',
            width: '48px',
            height: '48px'
        },
        '.peopleCount': {
            border: '1px solid #94A3B8',
            borderRadius: '7px',
            height: '36px',
            padding: '3px 8px'
        },
        '.listMoreBtn': {
            width: '22px',
            height: '36px',
            borderRadius: '8px',
            background: '#EDEDED'
        },
    },
    '.filterIcon': {
        '& svg': {
            width: '22px'
        },
        '.strokePath': {
            stroke: '#fff'
        }
    },

    //   create
    '.workspaceItem': {
        boxShadow: '0px 4px 4px 0px #0000000A',
        borderRadius: '8px',
        padding: '8px',
        border: '1px solid #E9E9E9',
        marginBottom: '16px',
        '&.selected': {
            borderColor: theme.palette.chips.active.color,
        }
    },
    '.importChip': {
        fontSize: '13px',
        fontWeight: '500',
        background: `${theme.palette.chips.active.bg}50`,
        color: theme.palette.chips.active.color,
        padding: '6px 12px',
        borderRadius: '30px',
    },
    '.assetWorkspaceItem': {
        boxShadow: '0px 4px 4px 0px #0000000A',
        borderRadius: '8px',
        padding: '8.5px 8px',
        border: '1px solid #2563EB',
    },
    '.assetDragItem': {
        boxShadow: '0px 4px 4px 0px #0000000A',
        border: '1px solid #E9E9E9',
        borderRadius: '8px',
        padding: '8.5px 8px',
        '.fillStroke': {
            stroke: '#2563EB'
        },
        '.fillPath': {
            fill: '#2563EB'
        }
    },
    '.removeBtn': {
        fontSize: '13px',
        fontWeight: '500',
        background: `${theme.palette.chips.delete.bg}50`,
        color: theme.palette.chips.delete.color,
        padding: '6px 12px',
        borderRadius: '30px',
        textAlign: 'center'
    },
    '.dayItem': {
        border: '1px solid #DDDDDD',
        borderRadius: '12px',
        background: '#F7F6F6',
        padding: '20px'
    },
    '.MuiStepper-root': {
        '.MuiStepLabel-label': {
            '&.Mui-active': {
                '.MuiTypography-root': {
                    color: '#2563EB'
                }
            }
        }
    },
    '.searchBox': {
        '.MuiOutlinedInput-root': {
            background: theme.palette.containers.card
        }
    }
});

export default Style;
