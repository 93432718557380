import { Avatar, Button, Checkbox, DialogActions, DialogContent, DialogContentText, Divider, Grid, IconButton, InputAdornment, TextField, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import Style from "./Style";
import moment from 'moment';
import { LabTabs } from "../../components/Tabs/Tabs"
import {
  AgenciICon,
  CheckFilled,
  FilledEmailIcon,
  // InfiniteIcon,
  PlanTag,
  ProDimond,
  // ShareCircleIcon,
  UserIcon,
  UsersIcon,
  InactiveIcon,
  TrialIcon,
  EditIcon3,
  CloseIcon,
  AdorementEmail,
  PhoneIcon,
  PinIcon,
  // DownloadIcon,
  InvoiceDownloadIcon,
  CircleIcon
} from "../../assets/svg";
import {
  StyledDialog,
  StyledCloseIconButton,
  StyledGrid,
  StyledTitle,
  StyledTypograhy,
  StyledTextfield
} from './dialogstyled';

import Table from "../../components/Table";
import CreatePaymentLink from "./CreatePaymentLink";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { agencyEmailupdate, existingmailVerify, getAgenciesById, getAgencyDeatil, otpgenerate, otpverify, resendotp } from "../../store/actions/agenciesAction"
import { setActiveFilterHeader, setDeactivateFilterHeader } from "../../store/reducers/uiReducer"
import { clearAgencyDetails } from "../../store/reducers/agenciesReducer"
import {
  setPageSize,
  setPage,

} from "../../store/reducers/agenciesReducer";
import theme from "../../theme";
import BillingAction from "./components/actions/billingInvoice/index"
import UpdateCustomerForm from "./Editform";
import CancelSubscription from "./components/actions/billingInvoice/cancelSubscription";
import Modal from "../../components/Modal"
import SignUpTracker from "./signupTracker";
const AgencyDetail = (props) => {
  const { className } = props;
  const { agency_id } = useParams()
  const dispatch = useDispatch()

  const { list } = useSelector((state) => state.agencies);
  // const { filterHeader, filterBar } = useSelector((state) => state.ui);

  const [detail, setDetail] = useState("billingInvoices")
  const [TableHeader, setTableHeader] = useState(list?.billingInvoices)
  const [opendialog, setopendialog] = useState(false)
  const [dialogshow, setDialogshow] = useState(false)
  const [verifycheck, setverifycheck] = useState(true)
  const [changeEmail, setEmailchange] = useState(false)
  const [newemail, setnewEmail] = useState(list?.agencyDetails?.agency_email || "");
  const [Otpgenerate, setOtpgenerate] = useState(false)
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [changeplan, setchangeplan] = useState(false)
  const [editForm, setEditform] = useState(false)
  const [planStatus, setPlanStatus] = useState("")
  const invoicePdfs = list?.agencyDetails?.invoicePdfs
  //const invoicePdfs = list?.results?.map(result => result.invoice_pdf) || [];


  const { pagination } = list

  const filters = [
    { key: "status", label: "Status", options: ['0', '1', '2'] },
  ];
  const formattedDate = (trialEnd) => {

    const convertedDate = new Date(trialEnd * 1000);


    // Check if the date is valid
    if (isNaN(convertedDate.getTime())) {
      return "Need to subscribe";
    }

    // Format the date as needed
    return convertedDate.toLocaleDateString();
  }
  const editFormopen = () => {
    setEditform(true)
  }
  const editFormclose = () => {
    setEditform(false)
  }
  useEffect(() => {
    dispatch(getAgenciesById(agency_id))
    dispatch(setActiveFilterHeader())
  }, [agency_id])

  useEffect(() => {
    setnewEmail(list?.agencyDetails?.agency_email || "");
  }, [list]);

  const dialogOpen = () => {
    setopendialog(true)
  }

  const dialogClose = () => {
    setopendialog(false)

    setStepper(0)
  }

  const quitDialog = () => {
    setDialogshow(false)
  }

  const handleChangePlan = () => {
    setchangeplan(true)
    setDialogshow(true)
  }
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setnewEmail(value);


    if (!validateEmail(value)) {
      setError('Please enter a valid email address.');
    } else {
      setError('');
    }
  };
  function mailcheck() {
    setverifycheck(false)
  }

  const emailverify = () => {
    dispatch(existingmailVerify({
      mail: newemail, cb: () => {
        mailcheck()
        setStepper(4)
      }, cb2: () => {
        // handlesubmit2()
        setStepper(1)
      }
    }))
    setverifycheck(false)
  }
  const obj = {
    email: list?.agencyDetails?.agency_email, ID: parseInt(agency_id), newEmail: newemail, name: list?.agencyDetails?.agency_name, cb: () => {

    }
  }
  const handleinstantupdate = () => {

    dispatch(agencyEmailupdate(
      obj
    ))
    dialogClose();
  };
  const handleProceed = () => {
    setEmailchange(true)
    setStepper(1)
  }
  // function handlesubmit2() {

  //   setEmailchange(true)
  // }

  const agencyName = (item) => {

    return <span>{item.rowData.agency_name
    }</span>;
  };

  const StatusCell = (props) => {

    const { item } = props

    const color = item === "paid" ? "#D4F6D2" : item === "draft" ? "#FFDEDE" : item === "refund" ? "#FFE1F0" : "orange";
    const textColor = item === "paid" ? "#0E8E2A" : item === "draft" ? "#FF3B3B" : item === "refund" ? "#FF5EB0" : "orange"
    const width = "100px"; // Set a specific width for consistency
    const renderText = item
    return (
      <span
        style={{
          display: "inline-block",
          width: width,
          textAlign: "center",
          color: textColor,
          backgroundColor: color,
          borderRadius: "6px",
          padding: "8px 10px",
        }}
      >
        {renderText}
      </span>
    );
  };
  const CreatedOnCell = ({ item }) => {
    const formatDate = (isoDate) => {
      return moment(isoDate).format('DD MMMM YYYY');
    };
    return <span>{formatDate(item)}</span>;
  };
  const AmoutCell = ({ item }) => {

    return <span style={{ color: theme.palette.colors.blue }} >{item}$</span  >;
  };
  const cellMap = {
    name: agencyName,
    status: StatusCell,
    amount_paid: AmoutCell,
    // profile_name: profileName,
    created_on: CreatedOnCell,
    action: BillingAction
  };
  const header = [
    { field: 'amount', label: 'Amount' },
    { field: 'created_on', label: 'Created On' },
    { field: 'status', label: 'Status' },
    // { field: 'username', label: 'User name' },
    { field: 'agency_email', label: 'Agency Email' },
    // { field: 'profile_name', label: 'Profile Name' },
    { field: "plan_name", label: "Plan" },

    { field: "action", label: "ACTION" }
  ]
  //   {
  //     name: "Infinity AI",
  //     icon: <InfiniteIcon />,
  //     description:
  //       "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
  //     switchText: "Active since 12 Jun 2023",
  //   },
  //   {
  //     name: "Infinity AI",
  //     icon: <ShareCircleIcon />,
  //     description:
  //       "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
  //     switchText: "Active since 12 Jun 2023",
  //   },
  //   {
  //     name: "Infinity AI",
  //     icon: <ShareCircleIcon />,
  //     description:
  //       "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
  //     switchText: "Active since 12 Jun 2023",
  //   },
  //   {
  //     name: "Infinity AI",
  //     icon: <ShareCircleIcon />,
  //     description:
  //       "The AI generated simulation to make the flow easier and time saver, additional $29 to be paid by the agency to have this feature. Chat and brain section is adopted with AI.",
  //     switchText: "Active since 12 Jun 2023",
  //   },
  // ];
  const TabOption = [
    {
      name: "Billing Invoices",
      value: "billingInvoices"
    },

  ]
  const handleDownloadAllInvoice = async () => {
    if (invoicePdfs && invoicePdfs.length > 0) {
      for (const invoice of invoicePdfs) {
        const link = document.createElement('a');
        link.href = invoice.pdfUrl;
        link.target = '_blank';
        link.download = `Invoice_${invoice.id}.pdf`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Wait for a short duration before triggering the next download
        await new Promise(resolve => setTimeout(resolve, 500)); // 500 ms delay
      }
    } else {
      alert('No invoices available for download.');
    }
  };



  const handleTabChange = (e, value) => {
    setDetail(e)
    setTableHeader(e === 'billingInvoices' ? list.billingInvoices : e === 'connectedInvoices' ? list.connectedInvoices : e === 'clients' ? list.clients : header)
    dispatch(getAgencyDeatil({ id: agency_id, detail: e, stripe_id: list?.agencyDetails?.stripe_id ? list?.agencyDetails?.stripe_id : "", subscription_id: list?.agencyDetails?.sub_id ? list?.agencyDetails?.sub_id : "", data: pagination }))
  }

  useEffect(() => {
    if (list?.agencyDetails?.stripe_id && list?.agencyDetails?.sub_id) {

      dispatch(getAgencyDeatil({ id: agency_id, detail: detail, stripe_id: list?.agencyDetails?.stripe_id ? list?.agencyDetails?.stripe_id : "", subscription_id: list?.agencyDetails?.sub_id ? list?.agencyDetails?.sub_id : "", data: pagination }))
    }

  }, [list?.agencyDetails?.sub_id])

  // useEffect(() => {



  //     dispatch(getAgencyDeatil({ id: agency_id, detail: detail, list?.agencyDetails?.stripe_id:list?.agencyDetails?.stripe_id?list?.agencyDetails?.stripe_id:"" ,subscription_id:list?.agencyDetails?.sub_id ? list?.agencyDetails?.sub_id :"" , data: pagination }))


  // }, [list?.agencyDetails?.stripe_id,list?.agencyDetails?.sub_id,detail])
  const handlePageSize = (e) => {
    dispatch(setPageSize(e.target.value));
  };

  const handlePagination = (e, pageValue) => {
    dispatch(setPage(pageValue));
  };

  const verifyAndupdate = (confirm) => {
    if (confirm) {
      dispatch(otpgenerate({
        email: list?.agencyDetails?.agency_email, newEmail: newemail, ID: parseInt(agency_id), name: list?.agencyDetails?.agency_name, cb: () => {
          setStepper(2)
        }
      },
      ))
    }
    setEmailchange(false)
    setOtpgenerate(true)
  }

  const handleResendotp = (confirm) => {
    if (confirm) {
      dispatch(resendotp({
        email: list?.agencyDetails?.agency_email, newEmail: newemail, ID: parseInt(agency_id), name: list?.agencyDetails?.agency_name, cb: () => {
          setStepper(3)
        }
      }))
    }
    setIsResendEnabled(false);
    setCountdown(60);
  }
  useEffect(() => {
    if (!isResendEnabled) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResendEnabled(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isResendEnabled]);
  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.replace(/\D/g, '');
    setOtp(newOtp);

    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleOtpverify = (confirm) => {
    if (confirm) {
      dispatch(otpverify({
        email: list?.agencyDetails?.agency_email, newEmail: newemail, ID: parseInt(agency_id), name: list?.agencyDetails?.agency_name, otp: otp.join(''), cb: () => { }

      }))
    }
  }
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text').slice(0, 6);
    const digits = pastedData.split('').map(d => d.replace(/\D/g, ''));

    const newOtp = [...otp];
    digits.forEach((digit, i) => {
      if (i < newOtp.length) {
        newOtp[i] = digit;
      }
    });

    setOtp(newOtp);


    const nextEmptyIndex = newOtp.findIndex(d => !d);
    if (nextEmptyIndex !== -1) {
      document.getElementById(`otp-input-${nextEmptyIndex}`).focus();
    } else {
      document.getElementById(`otp-input-${digits.length - 1}`).focus();
    }
  };
  const [stepper, setStepper] = useState(0)
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const ModalData = {
    subscription_id: list?.agencyDetails?.sub_id,
    amount: list?.agencyDetails?.price,
    invoice_id: list?.agencyDetails?.subscriptionDetails?.latest_invoice,


  }

  const stepsData = list?.agencyDetails?.signupTrack;
  const getPlanDetails = () => {
    setPlanStatus("");

    if (list?.agencyDetails) {
      const { status, subscriptionDetails } = list.agencyDetails;

      if (status === "Cancelled" && subscriptionDetails) {
        const date = new Date(subscriptionDetails.cancel_at * 1000);
        const formattedDate = date.toLocaleDateString();

        if (subscriptionDetails.cancel_at_period_end) {
          setPlanStatus(`Cancel at current period end ${formattedDate}`);
        } else if (subscriptionDetails.status === "canceled") {
          setPlanStatus("Cancelled");
        } else {
          setPlanStatus(`Cancel at ${formattedDate}`);
        }
      } else if (status === "Trial") {
        setPlanStatus("Trial");
      } else if (status === "Active") {
        setPlanStatus("Active");
      }
    }
  };

  useEffect(() => {
    // Call `getPlanDetails` when the component mounts or `agency_id` changes
    getPlanDetails();

    // Clear `planStatus` on unmount to prevent data leaking to other customerss
  }, [agency_id, list]);

  useEffect(() => {
    return () => {
      setPlanStatus("");
      dispatch(setDeactivateFilterHeader())
      dispatch(clearAgencyDetails());
    };

  }, [])
  console.log(planStatus, "planStatus fege")
  console.log("steps data", stepsData)

  const handlDeleteAgnecy = () => { };

  return (

    <Grid container className={className}>
      {/* Agenci detil Header */}
      <Grid container justifyContent={"space-between"} alignItems={"center"} className="breadcrumb">
        <Grid className="dflex aCenter">
          {/* <Typography variant="h5" className="fw-700" color={"text.greyText2"}>
            Agency {" > "}
          </Typography>
          <Typography variant="h5" className="fw-700">Agency Info</Typography> */}
        </Grid>
        {/* Create payment */}
        {/* <CreatePaymentLink /> */}

        {/* handleActions */}
        <Grid className="flexAlign">
          <SignUpTracker stepsData={stepsData} />
          <Button variant="outlined" size="small" color="secondary" className="redBtn" sx={{ mr: 1, ml: 1 }} onClick={handlDeleteAgnecy}>Delete Agency</Button>
          <Button variant="outlined" size="small" color="primary" className="BlueBtn" onClick={editFormopen}>Edit</Button>
        </Grid>
        {/* handleActions */}


      </Grid>
      {/* <Grid className="dialog_box"> */}
      <StyledDialog
        open={opendialog}
        onClose={dialogClose}
        className='className'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      // PaperProps={{
      //   style: {
      //     width: '507px',
      //     height: '215',
      //     borderRadius:"10px"
      //   },
      // }}
      >
        <StyledGrid >
          <StyledTitle id="alert-dialog-title">
            {"Email Change Request"}
          </StyledTitle>
          <StyledCloseIconButton onClick={dialogClose}><CloseIcon /></StyledCloseIconButton>
        </StyledGrid>
        <Divider style={{ margin: '0px 24px' }} />
        <DialogContent className="Dialog_Content">
          <DialogContentText id="alert-dialog-description">

            {stepper === 0 ? (
              <>
                <StyledTypograhy>Are you sure you want change the email id of the selected agency’s user mentioned ?</StyledTypograhy>
                <StyledTextfield
                  autoFocus
                  margin="dense"
                  id="new-email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={newemail}
                  onChange={handleEmailChange}
                  error={!!error}
                  helperText={error}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AdorementEmail />
                      </InputAdornment>
                    ),
                  }}

                />
              </>
            ) : <>
              {stepper === 1 ? (
                <>
                  <StyledTypograhy>This email was used before. Changing it will deactivate all associated profiles and data. Are you sure you want to proceed ?</StyledTypograhy>
                </>
              ) : <>
                {stepper === 2 ? (
                  <>
                    <StyledTypograhy>Please enter OTP to verify</StyledTypograhy>
                    <Grid container spacing={1} justifyContent="center" sx={{ mt: 2 }}>
                      {otp.map((digit, index) => (
                        <Grid item key={index}>
                          <TextField
                            id={`otp-input-${index}`}
                            variant="outlined"
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={(e) => handlePaste(e, index)}
                            inputProps={{
                              maxLength: 1,
                            }}
                            sx={{ width: '40px', textAlign: 'center' }}
                          />
                        </Grid>
                      ))}
                    </Grid>

                  </>
                ) : <>
                  <Typography>There is an existing Mail id in this do you need to delete this credential and continue?</Typography>

                </>}
              </>}
            </>
            }

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {stepper === 0 ? (
            <>
              <Button onClick={dialogClose} variant="outlined" color="primary">
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={emailverify}>
                Submit
              </Button>
            </>
          ) : <>
            {stepper === 1 ? (
              <>
                <Button variant="outlined" color="primary" onClick={handleinstantupdate}>Instant Update</Button>
                <Button variant="contained" color="primary" onClick={verifyAndupdate}>Verify and update</Button>
              </>
            ) : <>
              {stepper === 2 ? (
                <>
                  < Button variant="outlined" color={isResendEnabled ? 'primary' : 'secondary'} onClick={handleResendotp} disabled={!isResendEnabled} sx={{ borderColor: isResendEnabled ? 'primary.main' : 'secondary.main', color: isResendEnabled ? 'primary.main' : 'secondary.main', }}>
                    {isResendEnabled ? 'Resend OTP' : `Resend OTP in ${countdown}s`}
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleOtpverify}>Submit</Button>
                </>
              ) : <>
                <>
                  <Button variant="contained" color="primary" onClick={handleProceed}>Proceed</Button>
                  <Button variant="outlined" color="primary" onClick={dialogClose}>Cancel</Button>

                </>
              </>}
            </>}
          </>}
        </DialogActions>
      </StyledDialog>
      {/* </Grid> */}
      {/* Agenci detil Header */}
      {/* Agency Card */}
      <Grid item xs={12} className={`agenciDetailCard`}>
        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item className="">
            <Grid item className="dflex aStart">
              <Grid item className="agenciName">
                <Grid>
                  <Avatar />
                </Grid>
                <Grid sx={{ ml: 2 }}>
                  <Typography
                    variant="h4"
                    className="fw-600"
                    sx={{ mb: 1 }}
                    color={'primaryText'}
                  >
                    {list?.agencyDetails?.agency_name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="dflex"
                    color={"text.greyText2"}
                  >
                    <AgenciICon />
                    <Grid sx={{ ml: 1 }}>Substance Digital Branding</Grid>
                  </Typography>
                </Grid>

              </Grid>
              <Grid item sx={{ ml: 3 }}>
                <Grid className={`${list?.agencyDetails?.status === "Trial" ? "agenciTrail" : list?.agencyDetails?.status === "Active" ? "agenciStatusActive" : "agenciInactive"} chipItem`}>
                  {list?.agencyDetails?.status === "Trial" ? <TrialIcon /> : list?.agencyDetails?.status === "Active" ? <CheckFilled /> : <InactiveIcon />}
                  <Typography
                    sx={{ ml: 1 }}
                    variant="body2"
                    color={'text.primaryText'}
                  >
                    {list?.agencyDetails?.status}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Grid item>
            {/* <Grid className="agenciDate chipItem">
              <AlertIcon />
              <Typography variant="body2" color={"text.redText"} sx={{ ml: 1 }}>
                Expires by
              </Typography>
              <Typography
                variant="body2"
                color={"text.greyText1"}
                sx={{ ml: 2 }}
              >
                12 June 23{" "}
              </Typography>
            </Grid> */}
          </Grid>

          {/* Plan Card */}
          <Grid className=" planCardWrapper">
            <Grid item className="planCard">
              <PlanTag />
              <Grid item container justifyContent={"space-between"} className="agenciPlan chipItem">
                <Grid className="flexAlign">
                  <ProDimond />
                  <Typography
                    variant="body1"
                    className="ml-1 fw-600"
                    color={"text.primaryText"}
                  >
                    {list?.agencyDetails?.plan_name}
                  </Typography>
                </Grid>
                <Grid className="dflex aBaseline">
                  <Typography
                    variant="h1"
                    color={"text.primaryText"}
                    className="fw-700 ml-3"              >
                    ${list?.agencyDetails?.price}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={"text.primaryText"}
                    className="pl5"
                  >
                    USD/Month
                  </Typography>
                </Grid>
              </Grid>
              <Grid className="dflex aCenter" sx={{ pb: 1 }}>
                <Typography variant="body2" color={"text.greyText3"}>Next Invoice On :</Typography>
                <Typography variant="body2" color={"textSecondary"}>{list?.agencyDetails?.status === "Trial" ? formattedDate(list?.agencyDetails?.subscriptionDetails?.trial_end) : list?.agencyDetails?.status === "Active" ? formattedDate(list?.agencyDetails?.subscriptionDetails?.current_period_end) : "Need to subscribe"}</Typography>
              </Grid>
              <Grid className="dflex aCenter" sx={{ pb: 1 }}>
                <Typography variant="body2" color={"text.greyText3"}>Next Invoice Amount :</Typography>
                <Typography variant="body2" color={"textSecondary"}>{list?.agencyDetails?.status === "Trial" ? formattedDate(list?.agencyDetails?.subscriptionDetails?.trial_end) : list?.agencyDetails?.status === "Active" ? formattedDate(list?.agencyDetails?.subscriptionDetails?.current_period_end) : "Need to subscribe"}</Typography>
              </Grid>
              {/* <ChangePlan /> */}
              <Grid>
                <Grid container justifyContent={"space-between"}>
                  <Button variant="outlined" color="primary" className={'redBtn'} onClick={() => setOpenDialog(true)}
                    disabled={list?.agencyDetails?.subscriptionDetails ? planStatus.toLocaleLowerCase().includes("cancel") : true}>
                    Cancel Subscription
                  </Button>
                  <Button variant="outlined" color="primary" sx={{ ml: 3 }}>Change Plan</Button>
                </Grid>
                <Modal
                  open={openDialog}
                  handleClose={handleDialogClose}
                  title={'Cancel Subscription'}
                >
                  <CancelSubscription rowData={ModalData} onClose={handleDialogClose} />
                </Modal>
                {/* <Typography variant="body2" color={"text.greyText3"}>Coupon Code :</Typography>
                <Typography variant="body2" className="fw-600" color={"textSecondary"}>AF@#@$</Typography> */}
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ mt: 2 }}>
              <Typography variant="body1" color={"textSecondary"}>Accreditation</Typography>
              {/* change class name inactive to active */}
              <Grid className="accrediationBtn inactive">
                <Checkbox sx={{mr:0.5}} icon={<CircleIcon />} checkedIcon={<CheckFilled />} />
                Special Flow (2/56)
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item className="editIcon">
            <IconButton onClick={editFormopen}><EditIcon3 /></IconButton>
          </Grid> */}
          {/* Plan Card */}
        </Grid>

        {/* Edit and View */}
        <UpdateCustomerForm open={editForm} onClose={editFormclose} ID={parseInt(agency_id)} />


        <Grid container justifyContent={"space-between"}>

          {/* Users Count */}
          <Grid item>
            <Grid container className="usersCount">
              <Grid item className="usersCountChip">
                <Grid className="UsersIcon">
                  <UsersIcon />
                </Grid>
                <Typography variant="body1" className="fw-600 ml-1" color={"text.greyText1"}>
                  Clients
                </Typography>
                <Grid className="dflex aBaseline ml-2">
                  <Typography variant="body1" className="fw-600" color={"text.blueText"}>
                    {list?.agencyDetails?.clinetCount}
                  </Typography>
                  <Typography variant="body2" className="fs-10 pl5" color={"text.greyText1"}>
                    nos
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className="usersCountChip ml-3">
                <Grid className="UsersIcon">
                  <UserIcon />
                </Grid>
                <Typography variant="body1" className="fw-600 ml-1" color={"text.greyText1"}>
                  Users
                </Typography>
                <Grid className="dflex aBaseline ml-2">
                  <Typography variant="body1" className="fw-600" color={"text.blueText"}>
                    {list?.agencyDetails?.agencyClientsUsersCount}
                  </Typography>
                  <Typography variant="body2" className="fs-10 pl5" color={"text.greyText1"}>
                    nos
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Users Count */}

          {/* Change Plan */}
          {/* <Grid item className="changePlan">
            <Button onClick={handleChangePlan}>Change Plan</Button>
            {changeplan && (
              <StyledDialog
                open={dialogshow}
                onClose={quitDialog}
                className='className'
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <StyledGrid>
                  <StyledTitle id="alert-dialog-title">
                    {"ChangePlan"}
                  </StyledTitle>
                  <StyledCloseIconButton onClick={quitDialog}><CloseIcon /></StyledCloseIconButton>
                </StyledGrid>
                <DialogContent className="Dialog_Content">
                  <DialogContentText id="alert-dialog-description">
                    <StyledTextfield
                      autoFocus
                      margin="dense"
                      id="plan"
                      value={list?.agencyDetails?.plan_name}
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">

                            {"$"+list?.agencyDetails?.price}
                          </InputAdornment>
                        )
                      }}
                    >
                    </StyledTextfield>

                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={quitDialog} variant="outlined" color="primary">
                Cancel
              </Button>
              <Button variant="contained" color="primary" >
                Update
              </Button>
                </DialogActions>

              </StyledDialog>
            )}

          </Grid> */}
          {/* transaction card */}
        </Grid>

        <Grid container className="agenciContact" spacing={5} >
          <Grid item className="dflex pt-3" md={3} sx={12}>
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <FilledEmailIcon />
            </Grid>
            <Grid>
              <Grid className="email_contaner">
                <Typography variant="body2" color={"text.greyText3"}>Email</Typography>
                <IconButton className="EditIcon" onClick={dialogOpen}><EditIcon3 /></IconButton>
              </Grid>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                {list?.agencyDetails?.agency_email}
                {/* <IconButton onClick={dialogOpen}>
                  <EditIcon/>
                </IconButton> */}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3" md={3} sx={12}>
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <PhoneIcon />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Phone
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                (562) 975-6876
                XXXXXXXXX
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3" md={3} sx={12}>
            <Grid className="leftItem" sx={{ mr: 2 }}>
              <PinIcon />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Address
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                45 Roker Terrace, Latheronweel, KW5 8NW, London
              </Typography>
            </Grid>
          </Grid>
          <Grid item className="dflex pt-3" md={3} sx={12}>
            <Grid sx={{ mr: 2 }}>
              <Avatar />
            </Grid>
            <Grid>
              <Typography variant="body2" color={"text.greyText3"}>
                Owner
              </Typography>
              <Typography
                variant="body1"
                color={"text.primaryText"}
                className="fw-500"
              >
                {list?.agencyDetails?.first_name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* // Agency Card */}

      {/* Agenci card */}
      {/* <Grid item xs={12}>
        <Grid container className="paymentCardRoot" spacing={3}>
          {cardData.map((item) => (
            <Grid item xs={12} sm={6} md={3}>
              <PaymentCard
                icon={item.icon}
                name={item.name}
                description={item.description}
                switchText={item.switchText}
              />
            </Grid>
          ))}
        </Grid>
      </Grid> */}
      {/* Agenci card */}

      {/* Agenci Payments */}
      <Grid item xs={12} sx={{ pt: 3 }}>
        <Grid container className="agencyPayments">
          <Grid item xs={12} className="top">
            <Grid container justifyContent={"space-between"}>
              <LabTabs onChange={handleTabChange} tabs={TabOption} tabValue={detail} />

              <Button
                variant="text"
                size="small"
                startIcon={<InvoiceDownloadIcon />}
                color="secondary"
                onClick={handleDownloadAllInvoice}
              >
                Download All
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} className="pl-1 pr-1">
            <Table
              // title={"Agency Payments"}
              data={list.results || []}
              cellMap={cellMap}
              headers={TableHeader}
              pagination={list.pagination}
              // filters={filters}
              // filterOptions={filters}
              showHeader={true}
              filterHeader={false}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* // Agenci Payments */}
    </Grid>
  );
};

export default styled(AgencyDetail)(Style);
