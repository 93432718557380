import React from "react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  styled,
 
} from "@mui/material";
import Style from "./style";
import { BellIcon, FlozyLogo, LogOutIcon, PasswordSettingIcon, ProfileIcon} from "../../assets/svg";
import { NavLink, useLocation } from "react-router-dom";

import AutocompleteComponent from "../../containers/GlobalSearch/index"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AppHeaderComponent = (props) => {
  const { logout, className,PasswordSetting } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profile = useSelector((state) => state.profileUser.list.profile);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  
  const handlePassword = (data) => {
    navigate("/setting/password");
    handleClose()
  };
  const handleProfile = (data) => {
    navigate("/setting/profile/edit");
    handleClose()
  };

  return (
    <Grid
      container
      justifyContent={"space-between"}
      alignItems={"center"}
      className={className}
    >
      <Grid>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FlozyLogo />
          <Typography variant="h4" sx={{ ml: 1 }} color={"text.primaryText"}>
            Flozy
          </Typography>
        </Box>
      </Grid>
      <Grid>
      <AutocompleteComponent
        />   
      </Grid>
      <Grid>
        <IconButton>
          <BellIcon />
        </IconButton>
        <IconButton onClick={handleClick}>
          <Avatar src={profile?.image_url} alt="Profile Picture"/>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleProfile}>
                <ProfileIcon />
                <ListItemText className="ml-1" primary="My Profile" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding >
              <ListItemButton onClick={handlePassword}>
              <PasswordSettingIcon />
                <ListItemText  className="ml-1" primary="Password Settings" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={logout}>
              <LogOutIcon />
                <ListItemText className="ml-1" primary="Logout" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </Grid>
    </Grid>
  );
};

const AppHeader = styled(AppHeaderComponent)(({ theme }) => Style(theme));

export default AppHeader;
