import React from 'react';
import ReactECharts from 'echarts-for-react';

const DoughnetChart = ({ data = {}, startDate, endDate, style = { height: 400, width: '100%' } }) => {
  const plansData = data?.trialAgenciesCount?.plans || [];
  const totalLength = plansData.length;

  const start = new Date(startDate);
  const end = new Date(endDate);

  start.setHours(0, 0, 0, 0);
  end.setHours(23, 59, 59, 999);

  const planCounts = {};
  plansData.forEach(plan => {

    const agencyCount = plan.agencies?.filter(agency => {
      const createdOnDate = new Date(agency.created_on);
      return createdOnDate >= start && createdOnDate <= end;
    }).length;

    
    if (agencyCount > 0) {
      planCounts[plan.planName] = (planCounts[plan.planName] || 0) + agencyCount;
    }
  });

  const chartData = Object.keys(planCounts).map(planName => ({
    name: planName,
    value: planCounts[planName]
  }));

  
  const blueShades = [
    '#2463eb', '#729eff', '#259cea', '#9e86ff', '#94c9fd',
     '#23a4ee', '#1e8cfd', '#2a90f0'
  ];

    const colors = chartData.map((_, index) => blueShades[index % blueShades.length]);

  const chartOptions = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 15,
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: chartData, 
        itemStyle: {
          color: (params) => colors[params.dataIndex], 
        }
      }
    ]
  };

  return (
    <ReactECharts
      option={chartOptions}
      style={style}
      notMerge={true}
      lazyUpdate={true}
    />
  );
};

export default DoughnetChart;
