import { createSlice } from "@reduxjs/toolkit";
import { fetchPlanList,planMembers } from "../actions/planAction";

const initialState={
    list:{
        plans: [],
        planMembers:{}
    },
    loading: null,
}

export const planSlice = createSlice({
    name:"plans",
    initialState,
    reducers:{
        setPlans(state,action){
            state.list.plans= action.payload
        },
        setplanmembers(state,action){
            state.list.planMembers=action.payload
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchPlanList.pending,(state)=> {
            state.loading = true;
        })
        .addCase(fetchPlanList.fulfilled,(state , {payload})=> {
            
            state.list.plans = payload?.data?.planlist;
            state.loading=false
        })
        .addCase(fetchPlanList.rejected, (state,action) => {
            state.loading= false
        })
        
        builder
        .addCase(planMembers.pending,(state)=> {
            state.loading=true;
        })
        .addCase(planMembers.fulfilled,(state,{payload})=> {
            state.list.planMembers=payload?.data
            state.loading=false
        })
        .addCase(planMembers.rejected, (state,action) => {
            state.loading= false
        })
        
    },
   
})

export const {setPlans,setplanmembers} = planSlice.actions;
export default planSlice.reducer