import { createSlice } from "@reduxjs/toolkit";
import { deleteUserById, getUser } from "../actions/UserAction";

const initialState = {
    list: {
        results:[],
        headers: [
            { field: 'name', label: 'NAME' },
            { field: 'email', label: 'EMAIL' },
            { field: 'last_login', label: 'LAST LOGIN' },
            { field: 'created_on', label: 'CREATED ON' }, 
            { field: "username", label: "USER NAME" },
            { field: "action", label: "ACTION" },
      
           
          ],
          pagination: {
            count: 10,
            next: null,
            previous: null,
            page_size: 10,
            num_pages: 0,
            page_number: 1,
          },
          search: "",
          ordering: "created_on",
    orderBy: [
      { field: "created_on", label: "Invited On " },
      { field: "agency_name", label: "Agency Name" },
      { field: "invite_email", label: "Email" }
     

    ],
    },
    loading:null
};
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      setPageSize(state, action) {
        state.list.pagination.page_size = action.payload;
      },
      setPage(state, action) {
        state.list.pagination.page_number = action.payload;
      },
      setSearch(state, action) {
        state.list.pagination.page_number = 1;
        state.list.search = action.payload;
      },
      setOrdering(state, action) {
        state.list.ordering = action.payload;
      },
      addFilterRule(state, action) {
        state.list.filter.rules.push({
          index: (Math.max(state.list.filter.rules.map((m) => m.index)) || 0) + 1,
          field: "",
          operator: "",
          value: "",
        });
      },
      removeFilterRule(state, action) {
        state.list.filter.rules = state.list.filter.rules.filter(
          (f) => f.index !== action.payload
        );
      },
      updateFilterRule(state, action) {
        state.list.filter.rules = state.list.filter.rules.map((m) => {
          if (m.index === action.payload.index) {
            return action.payload;
          }
          return m;
        });
      },
      applyFilter(state, action) {
        state.list.pagination.page_number = 1;
        state.list.filter.params = action.payload;
      },
      clearFilter(state) {
        state.list.pagination.page_number = 1;
        state.list.filter.params = "";
        state.list.filter.rules = [{ index: 0, field: "", value: "" }];
      },
    },
    extraReducers: (builder) => {
      // add cases for getAgencies api calls
      builder
        .addCase(getUser.pending, (state) => {
          state.loading = true;
        })
        .addCase(getUser.fulfilled, (state, { payload }) => {
          state.list.pagination = {
            ...state.list.pagination,
            ...payload?.data?.pagination,
          };
  
          state.list.results = payload?.data?.results
          state.loading = false;
        })
        .addCase(getUser.rejected, (state) => {
          state.loading = false;
        });

      builder
        .addCase(deleteUserById.pending, (state) => {
          state.loading = true;
        })
        .addCase(deleteUserById.fulfilled, (state, { payload }) => {
          state.list.agencyDetails = payload?.data[0]
          state.loading = false;
        })
        .addCase(deleteUserById.rejected, (state) => {
          state.loading = false;
         });
  
      // add cases for other api calls
      // like builder.addCase
  
      
  
    },
  });
  
  export const {
    setPage,
    setPageSize,
    setSearch,
    setOrdering,
    addFilterRule,
    removeFilterRule,
    updateFilterRule,
    applyFilter,
    clearFilter,
  } = userSlice.actions;
  
  export default userSlice.reducer;