export const mapMenuData = (menuData, permissions = []) => {
  return menuData.reduce((acc, menuItem) => {
      // Keep "Home" route always visible
      if (menuItem.name === "Home") {
          acc.push(menuItem);
          return acc;
      }
      if (menuItem.name === "Campaign") {
        acc.push(menuItem);
        return acc;
    }

      // Ensure permissions is an array before using .find()
      const permissionModule = Array.isArray(permissions) && permissions.find((perm) => perm.id === menuItem.id);


      // Check if the module is present in the permissions and has read access
      if (permissionModule && permissionModule.read) {
          const newItem = { ...menuItem };

          // Process children based on submodule permissions
          if (menuItem.children && permissionModule.subModules) {
              newItem.children = menuItem.children.filter((child) => {
                  const submodulePermission = permissionModule.subModules.find(
                      (sub) => sub.module_name.toLowerCase() === child.name.toLowerCase() && sub.read === true
                  );
                  console.log(submodulePermission, "submodulePermission");

                  return submodulePermission && submodulePermission.read;
              });
          }

          // Add the module to the final menu if it has at least one child with read access or no children
          if (!menuItem.children || newItem.children.length > 0) {
              acc.push(newItem);
          }
      }

      return acc;
  }, []);
};
