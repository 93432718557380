import axios from "axios";
const REACT_SWEEPTP_HOST = process.env.REACT_APP_SWEEPTP_HOST
 const instance3 = axios.create({
  //  baseURL:"https://qa-supportserver.agenciflow.com/api/v1",
  // baseURL:"http://localhost:9000/api/v1",

  baseURL:REACT_SWEEPTP_HOST,
    // withCredentials:true,
    headers: {
      "Content-Type": "application/json",
    },
  })

  export default instance3; 