import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import EmailCampaign from "../containers/Campaign/emailCampaign";
import MonetiseCampaign from "../containers/Campaign/monetiseCampaign";
import MonetiseDetail from "../containers/Campaign/monetiseCampaign/detail";
import MonetiseCreate from "../containers/Campaign/monetiseCampaign/create";

const emailCampaignRoutes = [
    {
        path: '/campaign',
        element: <ProtectedRoute />,
        children: [
            {
                path: 'email',
                element: <EmailCampaign />
            },
            {
                path: 'monetise',
                element: <MonetiseCampaign />,
                children: [
                    {
                        path: 'monetise/:id',
                        element: <MonetiseDetail />
                    }
                ]
            },
            {
                path: 'monetise/:id',
                element: <MonetiseDetail />,
            },
            {
                path: 'monetise/create',
                element: <MonetiseCreate />,
            }
        ]
    }
]

export default emailCampaignRoutes
