import { Avatar, Button, Divider, Grid, styled, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Style from '../style';

function BasicInfo(props) {

    const { className } = props;

    return (
        <Grid item xs={12} className={className}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Basic Details</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>Name of the feature and status be mentioned here</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Campaign Name</Typography>
                            <TextField size='small' fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Campaign Start Date</Typography>
                            <TextField size='small' fullWidth />
                        </Grid>
                        <Grid item xs={12} sx={{ '.MuiOutlinedInput-root': { height: 'auto' } }}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Description</Typography>
                            <TextField size='small' fullWidth multiline minRows={3} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Campaign Link Details</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>Enter the link name and URL to keep your campaign organized</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Link Name</Typography>
                            <TextField size='small' fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Link URL</Typography>
                            <TextField size='small' fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Added By</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>The above feature was added by the following person/user into the sales dashboard.</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Generated By</Typography>
                            <Grid className='dflex'>
                                <Avatar  sx={{height: '40px', width: '40px'}} />
                                <Grid sx={{pl:2}}>
                                    <Typography variant='body1' sx={{ pb: 1 }}>Kennly Cube Henry</Typography>
                                    <Typography variant='body1' color={"text.greyText3"} className='fs-12' sx={{ pb: 1 }}>Team Lead - Customer Support</Typography>
                                    <Typography variant='body1' color={"text.greyText5"} className='fs-12' sx={{ pb: 1 }}>12 Jun 2023</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Recently Edited By</Typography>
                            <Grid className='dflex'>
                                <Avatar  sx={{height: '40px', width: '40px'}} />
                                <Grid sx={{pl:2}}>
                                    <Typography variant='body1' sx={{ pb: 1 }}>Kennly Cube Henry</Typography>
                                    <Typography variant='body1' color={"text.greyText3"} className='fs-12' sx={{ pb: 1 }}>Team Lead - Customer Support</Typography>
                                    <Typography variant='body1' color={"text.greyText5"} className='fs-12' sx={{ pb: 1 }}>12 Jun 2023</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

BasicInfo.defaultProps = {
    classes: {}
};

BasicInfo.propTypes = {
    className: PropTypes.string,
};


export default styled(BasicInfo)(Style);
