import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, CircularProgress, IconButton, Tooltip } from '@mui/material';
import Table from '../../components/Table';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCollabAgenciesAsync } from "../../store/actions/collabAction";
import { setPageSize, setPage, setSearch, setOrdering, addFilterRule, updateFilterRule, removeFilterRule, applyFilter, clearFilter, } from "../../store/reducers/collabReducer";

import { ActionCell } from '../../components/MoreAction/MoreAction';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { FaqCopyIcon, ThumbsDownIcon, ThumbsupIcon } from '../../assets/svg';
import { formatToReadableDate } from '../../helper/datehelper';

const Collab = (props) => {
    const { className } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tooltipText, setTooltipText] = useState("Copy");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { list, loading } = useSelector((state) => state.collab);
    const { pagination, filter } = list; 
    const search = (list.search || queryParams.get('search') || '').trim();
    useEffect(() => {
        const fetchCollabAgencies = () => {
            dispatch(getAllCollabAgenciesAsync({
                search: search,
                ...selectedFilters,
                ...pagination
            }));
        };
        fetchCollabAgencies();
    }, [dispatch, pagination.page_number, pagination.page_size, search]);
    const handlePagination = (e, pageValue) => {
        dispatch(setPage(pageValue));
    };

    const handlePageSize = (e) => {
        dispatch(setPageSize(e.target.value));
    };

    const handleSearch = (newSearch) => {
        dispatch(setSearch(newSearch));
    };

    const StatusCell = ({ converted, title = "" }) => {

        const textColor = converted === true ? "#0E8E2A" : "#FF3300";
        return (
            <Tooltip title={title} arrow>
                <span style={{
                    display: 'inline-block',
                    width: 'auto',
                    textAlign: 'center',
                    color: textColor,
                    borderRadius: '6px',
                    padding: '8px 10px'
                }}>
                    {converted === true ? <ThumbsupIcon /> : <ThumbsDownIcon />}
                </span>
            </Tooltip>
        );
    };
    const AgencyCell = ({ agency_id }) => {
        const backgroundColor = agency_id ? "#D4F6D2" : "#FFDEDE";
        const textColor = agency_id ? "#0E8E2A" : "#FF3300";
        return (
            <span style={{
                display: 'inline-block',
                width: '100px',
                textAlign: 'center',
                color: textColor,
                backgroundColor: backgroundColor,
                borderRadius: '6px',
                padding: '8px 10px'
            }}>
                {agency_id ? "Active" : "Pending"}
            </span>
        );
    };

    const handleCopyClick = (data) => {
        const { customer_email, collab_plan } = data;
        const url = `https://app.flozy.com/#/enroll/${collab_plan}?email=${customer_email}`;

        navigator.clipboard.writeText(url)
            .then(() => {
                setTooltipText("Copied");
                setTimeout(() => setTooltipText("Copy Link"), 2000);
            })
            .catch(err => {
                console.error("Failed to copy text: ", err);
            });
    };


    const Name = (item) => {

        return <span>{item.rowData.name}</span>;
    };
    const isconverted = (item) => {

        return <span><StatusCell converted={item.rowData.is_converted} title={item.rowData.is_converted ? "Cloning Done" : "No Cloning Yet"} />
        </span>;
    };

    const Email = (item) => {

        return <span>{item.rowData.email}</span>;
    };
    const No = (item) => {

        return <span>{item.row.id }</span>;
    };

    const collabPlan = (item) => {
        return <span>{item.rowData.collab_plan }</span>;
    };

    const agencyId = (item) => {

        return <span><StatusCell converted={item.rowData.agency_id ? true : false} title={item.rowData.agency_id ? "Agency Created" : "Not Created Yet"} /></span>;
    };
    
    const agstatus = (item) => {
       console.log("item---->",item.rowData.agenciesStatus?.status)
        return (
            <span>
              <StatusCell 
                converted={item.rowData.agenciesStatus?.status === 1 ? true : false} 
                title={item.rowData.agenciesStatus?.status === 1 ? "Agency Active" : "Agency Inactive"} 
              />
            </span>
          );
          
    };
    
    const CreatedOnCell = ({ item }) => {
        return <span>{formatToReadableDate(item.rowData)}</span>;
    };
  

    const cellMap = {
        No: No,
        name: Name,
        email: Email,
        is_converted: isconverted,
        agency_id: agencyId,
        agenystatus:agstatus,
        collab_plan: collabPlan,
        created_at: CreatedOnCell,
        action: (row) => {
            if (row.rowData.is_converted === true) {
                return <ActionCell rowData={row.rowData} onEdit={onEdit} />;
            }
            return (
                <Tooltip title={tooltipText} arrow>
                    <IconButton onClick={() => handleCopyClick(row.rowData)}>
                        <FaqCopyIcon />
                    </IconButton>
                </Tooltip>
            );
        }
    };

    const [selectedFilters, setSelectedFilters] = useState({
        status: '',
        startDate: '',
        endDate: '',
    });

    const filters = [
        { key: "status", label: "Status", options: [{ value: 0, label: "Paused" }, { value: 1, label: "Active" }], type: "checkbox" },
        { key: "date", label: "Date", options: [{ value: 'last', label: "is in the last" }, { value: 'equal_to', label: "is equal to" }, { value: 'between', label: "is between" }, { value: 'on_or_after', label: "is on or after" }, { value: 'before_or_on', label: "is before or on" },], type: "date" }
    ];

    const applyFilter = () => {
        const { status } = selectedFilters;
        const payload = {
            ...pagination,
            search,
            status: status || "", // Handle empty status
            startDate: selectedFilters.startDate || "",
            endDate: selectedFilters.endDate || ""
        };
        dispatch(getAllCollabAgenciesAsync(payload));
    };

    const onFilter = (reason, data) => {
        switch (reason) {
            case "add":
                dispatch(addFilterRule());
                break;
            case "remove":
                dispatch(removeFilterRule(data));
                break;
            case "update":
                dispatch(updateFilterRule(data));
                break;
            case "apply":
                dispatch(applyFilter());
                break;
            case "clear":
                dispatch(clearFilter());
                break;
            default:
                return;
        }
    };

    function onEdit(data) {
        navigate(`/agencies/edit/${data?.agency_id}`);
    }

    return (
        <Grid className={className}>
            <Table
                title={"Collaborations"}
                headers={list?.headers}
                data={list?.results}
                cellMap={cellMap}
                search={search}
                pagination={pagination}
                handlePagination={handlePagination}
                handlePageSize={handlePageSize}
                handleSearch={handleSearch}
                isSearch={true}
                isFilter
                filter={filters}
                onFilter={onFilter}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterOptions={filters}
                applyFilter={applyFilter}
                onEdit={onEdit}
            />
        </Grid>
    );
};

Collab.defaultProps = {
    className: ''
};

Collab.propTypes = {
    className: PropTypes.string,
};

export default Collab;

