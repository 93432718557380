import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, styled, Button, Typography, IconButton, Avatar, Popover, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Style from "./style";
import CardComponent from '../../../components/CardComponent';
import { BoardIcon, BrainIcon, ClientIcons, ExportIcon2, FlowsIcons, GlobeIcon, MoreIcon, MoreVerticleIcon, TaskIcon, UserCircleGreen } from '../../../assets/svg';


const MonetiseCampaign = (props) => {
    const { className } = props;

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleMoreClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const campaignList = [
        {
            id: '1',
            name: 'Black Friday Big Deal Campaign',
            status: 'active',
            days: '10days',
            users: 200,
            assets: [
                {
                    name: 'flows',
                    count: '2'
                },
                {
                    name: 'clients',
                    count: '2'
                },
                {
                    name: 'task',
                    count: '2'
                },
                {
                    name: 'boards',
                    count: '2'
                },
                {
                    name: 'pages',
                    count: '2'
                },
                {
                    name: 'funnel',
                    count: '2'
                }
            ]
        },
        {
            id: '1',
            name: 'Black Friday Big Deal Campaign2',
            status: 'draft',
            days: '5days',
            users: 0,
            assets: [
                {
                    name: 'flows',
                    count: '2'
                },
                {
                    name: 'clients',
                    count: '2'
                },
                {
                    name: 'task',
                    count: '2'
                },
                {
                    name: 'boards',
                    count: '2'
                },
                {
                    name: 'pages',
                    count: '2'
                },
                {
                    name: 'funnel',
                    count: '2'
                }
            ]
        }
    ];

    const assetIcon = (type) => {
        switch (type) {
            case "flows":
                return <FlowsIcons />
                break;
            case "clients":
                return <ClientIcons />
                break;
            case "task":
                return <TaskIcon />
                break;
            case "boards":
                return <BoardIcon />
                break;
            case "pages":
                return <GlobeIcon />
                break;
            case "funnel":
                return <FlowsIcons />
                break;
            case "docs":
                return <BrainIcon />
                break;

            default:
                break;
        }
    }

    return (
        <Grid item xs={12} className={className}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ pb: 2 }}>
                <Typography variant='h4' className='fw-700'>Campaign</Typography>
                <Button variant='contained' onClick={() => navigate("/campaign/monetise/create")}>Create</Button>
            </Grid>
            <CardComponent>

                {/* Campaign list */}
                {
                    campaignList.map((item) => (
                        <Grid container justifyContent={"space-between"} className='campaignItem' sx={{ mb: 2 }} key={item.id}>
                            <Grid item>
                                <Grid className='flexAlign'>
                                    <Typography variant='h4' className='fw-600'>{item.name}</Typography>
                                    <IconButton><ExportIcon2 /></IconButton>
                                    {/* pass the classname "active" / "draft" */}
                                    <Grid className={`statusChip ${item.status}`} sx={{ ml: 1 }}>{item.status}</Grid>
                                    <Typography variant='body2' className='fw-700' color={"text.orange"} sx={{ ml: 1 }}>{item.days}</Typography>
                                </Grid>
                                <Grid className='flexAlign'>
                                    <Avatar sx={{ width: '24px', height: '24px', mr: 0.5 }} />
                                    <Typography variant='body2' className='fs-12 fw-500'>Cat Products </Typography>
                                    <Grid className='flexAlign' sx={{ pl: 1, pr: 1 }}>
                                        <Typography variant='body2' className='fs-12' color={"text.greyText5"} sx={{ pr: 0.5 }}>Cat Products: </Typography>
                                        <Typography variant='body2' className='fs-12 fw-500' color={"text.greyText4"}>Cat Products </Typography>
                                    </Grid>
                                    <Avatar sx={{ width: '16px', height: '16px', mr: 0.5 }} />
                                    <Typography variant='body1' color={"textSecondary"}>David</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems={"center"} sx={{ gap: '10px' }}>
                                    {
                                        item.users > 0 &&
                                        <Grid className='peopleCount flexAlign'>
                                            <UserCircleGreen />
                                            <Typography variant='body1' sx={{ ml: 1 }}>{item.users}</Typography>
                                        </Grid>
                                    }
                                    {
                                        item.assets.map((assetItem) => (
                                            <Grid container direction={"column"} alignItems={"center"} justifyContent={"center"} className='assetItem' key={assetItem.name}>
                                                {assetIcon(assetItem.name)}
                                                <Typography variant='body1'>{assetItem.count}</Typography>
                                            </Grid>
                                        ))
                                    }

                                    <IconButton className='listMoreBtn' onClick={handleMoreClick}>
                                        <MoreVerticleIcon />
                                    </IconButton>


                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                }

                {/* More */}
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleMoreClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Grid sx={{ width: '100px' }}>
                        <MenuItem onClick={() => navigate("/campaign/monetise/1")}>View</MenuItem>
                        <MenuItem onClick={handleMoreClose}>Pass</MenuItem>
                        <MenuItem onClick={handleMoreClose}>Delete</MenuItem>
                    </Grid>
                </Popover>
                {/* More */}

                {/* Campaign list */}

            </CardComponent>
        </Grid>
    );
};

MonetiseCampaign.defaultProps = {
    classes: {}
};

MonetiseCampaign.propTypes = {
    className: PropTypes.string,
};

export default styled(MonetiseCampaign)(Style);
