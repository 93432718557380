import  instance1 from "../../utils/api1";


export const userService = {
    getUserdatas,
    getUserinfo,
    passwordreset,
    updateEmail,
    otpgenerate,
    otpverify,
    mailverify,
    resendOtp,
    deleteUserById
};


async function getUserdatas(data) {
  
    return await instance1.post(`user/getAll`, data);

    
  }

  async function getUserinfo(data) {
     
    return await instance1.post(`user/getuserinfo`, data);
  }
  async function passwordreset(data) {
     
    return await instance1.post(`user/reset`, data);
  }

  async function updateEmail(data) {
     
    return await instance1.post(`user/updateEmail`, data);
  }

  async function otpgenerate(data) {
     
    return await instance1.post(`user/otpgenerate`, data);
  }

  async function resendOtp(data) {
     
    return await instance1.post(`user/resendotp`, data);
  }

  async function otpverify(data) {
    return await instance1.post(`user/otpverify`, data);
  }

  async function mailverify(data) {
   return await instance1.post(`user/mailverify`, data);
 }


 async function deleteUserById(param) {
  return await instance1.delete(`user/delete/${param}`)
}