import { Box, Button, Grid, Step, StepButton, Stepper, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Style from './style';
import { Filter } from '../../../assets/svg';
import CardComponent from '../../../components/CardComponent';
import BasicInfo from './create/basicInfo';
import WorkspaceSelection from './create/workspaceSelection';
import AssetSettings from './create/assetSettings';
import Confirmation from './create/confirmation';

const steps = ['Basic Info', 'Workspace Selection', 'Asset Settings', 'Confirmation'];

function MonetiseCreate(props) {

    const { className } = props;

    const [activeStep, setActiveStep] = React.useState(0);

    const totalSteps = () => {
        return steps.length;
    };


    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    console.log("isLastStep", isLastStep);


    const handleNext = () => {
        const newActiveStep = activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <Grid item xs={12} className={`${className} h-100`}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ pb: 2 }}>
                <Grid className='flexAlign'>
                    <Typography variant='h6' className='fw-700' sx={{ pr: 3 }}>Asset Campaign</Typography>

                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    <Typography variant='h6' color={"textSecondary"} className='fw-500'>
                                        {label}
                                    </Typography>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>

                </Grid>
                <Grid className='flexAlign'>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {
                        activeStep !== 3 ?
                            <Button variant='contained' onClick={handleNext}>
                                Save & Continue
                            </Button> :
                            <Grid className='flexAlign'>
                                <Button variant='outlined' onClick={handleNext} sx={{ mr: 1 }}>
                                    Save
                                </Button>
                                <Button variant='contained' onClick={handleNext}>
                                    Publish
                                </Button>
                            </Grid>
                    }



                </Grid>
            </Grid>

            <CardComponent sx={{ height: 'calc(100% - 50px)', overflowY: 'auto' }}>

                {activeStep === 0 && <BasicInfo />}
                {activeStep === 1 && <WorkspaceSelection />}
                {activeStep === 2 && <AssetSettings />}
                {activeStep === 3 && <Confirmation />}

            </CardComponent>
        </Grid>
    )
}

MonetiseCreate.defaultProps = {
    classes: {}
};

MonetiseCreate.propTypes = {
    className: PropTypes.string,
};


export default styled(MonetiseCreate)(Style);
