import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, styled, Button } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';

import Style from "./style";
import { EmailEditor } from "react-email-editor";

import { setEditorContent } from "../../../store/reducers/emailCampaginReducer";

const EmailCampagin = (props) => {
    const { className } = props;
    const emailEditorRef = useRef(null);
    const dispatch = useDispatch();
    const { content } = useSelector((state) => state.emailEditor);
    const [isEditorLoaded, setEditorLoaded] = useState(false);

    const handleEditorReady = () => {
    setEditorLoaded(true);
    if (content && emailEditorRef.current) {
  
        try {
            emailEditorRef.current.editor.loadDesign(content);
        } catch (error) {
            console.error("🚨 ~ handleEditorReady ~ Error loading content:", error);
        }
    } else {
        console.warn("Editor is not ready or content is missing.");
    }
};


    const handleAddText = () => {
        if (isEditorLoaded && emailEditorRef.current) {
            const emailDesign = {
                html: "<div><h1>New Text Added</h1><p>This is dynamically added text!</p></div>",
            };

            emailEditorRef.current.editor.loadDesign(content );
        }
    };

    const handleLogEditorContent = () => {
        if (isEditorLoaded && emailEditorRef.current) {
            emailEditorRef.current.editor.saveDesign((data) => {
              

                dispatch(setEditorContent(data));
            });
        }
    };

    return (
        <Grid container spacing={3} className={className}>
            <Grid item xs={12}>
                <EmailEditor
                    ref={emailEditorRef}
                    onReady={handleEditorReady}
                />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={handleLogEditorContent} variant="contained">
                    Log Editor Content
                </Button>
                <Button onClick={handleAddText} variant="contained" style={{ marginLeft: "10px" }}>
                    Add Text
                </Button>
            </Grid>
        </Grid>
    );
};

EmailCampagin.defaultProps = {
    classes: {}
};

EmailCampagin.propTypes = {
    className: PropTypes.string,
};

export default styled(EmailCampagin)(Style);
