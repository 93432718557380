import { Avatar, Button, Grid, styled, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Style from '../style';
import { CheckFilled } from '../../../../assets/svg';

function WorkspaceSelection(props) {

    const { className } = props;

    return (
        <Grid item xs={12} className={`${className}`} sx={{ pb: 2 }}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Select Workspace</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>Name of the feature and status be mentioned here</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Search Workspace / Owner</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <TextField size='small' fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant='contained' fullWidth sx={{ height: '42px' }}>Search</Button>
                        </Grid>
                    </Grid>

                    <Grid className='workspaceItems' sx={{ mt: 2 }}>
                        {/* loop this */}
                        {/* Selected className after selected */}
                        <Grid container justifyContent={"space-between"} alignItems={"center"} className='workspaceItem'>
                            <Grid item className='flexAlign'>
                                <Avatar sx={{ height: '40px', width: '40px' }} />
                                <Grid sx={{ pl: 1.5 }}>
                                    <Typography variant='body1' className='fw-500' sx={{pb:0.5}}>Cat Products </Typography>
                                    <Grid className='flexAlign'>
                                        <Avatar sx={{ height: '14px', width: '14px' }} />
                                        <Typography variant='body1' className='fs-10' sx={{pl:0.5,pr:0.5}}>Cat Products </Typography>
                                        <Typography variant='body1' className='fs-10' color={"text.greyText3"}>Cat Products </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Button variant='outlined' className='blueBtn'>Add</Button>
                        </Grid>
                        {/* loop this */}

                        <Grid container justifyContent={"space-between"} alignItems={"center"} className='workspaceItem selected'>
                            <Grid item className='flexAlign'>
                                <Avatar sx={{ height: '40px', width: '40px' }} />
                                <Grid sx={{ pl: 1.5 }}>
                                    <Typography variant='body1' className='fw-500' sx={{pb:0.5}}>Cat Products </Typography>
                                    <Grid className='flexAlign'>
                                        <Avatar sx={{ height: '14px', width: '14px' }} />
                                        <Typography variant='body1' className='fs-10' sx={{pl:0.5,pr:0.5}}>Cat Products </Typography>
                                        <Typography variant='body1' className='fs-10' color={"text.greyText3"}>Cat Products </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className='importChip flexAlign'><Grid component={"span"} className='flexAlign' sx={{mr:1}}><CheckFilled /></Grid>Ready to import</Grid>
                        </Grid>

                        
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

WorkspaceSelection.defaultProps = {
    classes: {}
};

WorkspaceSelection.propTypes = {
    className: PropTypes.string,
};


export default styled(WorkspaceSelection)(Style);
