import instance2 from "./api";
import instance1 from "./api1"
import { accessToken } from "../store/reducers/authReducer";
import { getLogoutFunction } from '../helper/logouthelper'; 
import instance3 from "./sweetp_api";
const setup = (store) => {
// instace 1
instance1.interceptors.request.use(
  (config) => {
    const  {auth}  = store.getState(); 
    if (auth?.accessToken) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["x-access-token"] = auth?.accessToken; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const { dispatch  } = store;
instance1.interceptors.response.use(
  (res) => {
    if(res?.data?.accessToken){
     dispatch(accessToken(res?.data?.accessToken))
    }
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        // try {
        //   // const rs = await instance2.post("/auth/refreshtoken", {
        //   //   refreshToken: tokenService.action.getLocalRefreshToken(),
        //   // });
        //   console.log("401 error chace ap1")

        //   return instance2(originalConfig);
        // } catch (_error) {
        //   return Promise.reject(_error);
        // }
        // dispatch(auth?.authLogout());
        const logout = getLogoutFunction(); // Get the stored logout function
        if (logout) {
          logout(); // Call the logout function if available
        }
      }
    }

    return Promise.reject(err);
  }
);
// instace 2
  instance2.interceptors.request.use(
    (config) => {
      const  {auth}  = store.getState(); 
      if (auth?.accessToken) {
        // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        config.headers["x-access-token"] = auth?.accessToken; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  
  instance2.interceptors.response.use(
    (res) => {
      if(res?.data?.accessToken){
       dispatch(accessToken(res?.data?.accessToken))
      }
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          // originalConfig._retry = true;

          try {
            // const rs = await instance2.post("/auth/refreshtoken", {
            //   refreshToken: tokenService.action.getLocalRefreshToken(),
            // });

            // const { accessToken } = rs.data;
            // dispatch(auth?.authLogout());
            // tokenService.action.updateLocalAccessToken(accessToken);
            const logout = getLogoutFunction();
            if (logout) {
              logout(); // Call the logout function if available
            }
            // return instance2(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );

  //instance 3
  instance3.interceptors.request.use(
    (config) => {
      const  {auth}  = store.getState(); 
      if (auth?.accessToken) {
        // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
        config.headers["x-access-token"] = auth?.accessToken; // for Node.js Express back-end
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  
  instance3.interceptors.response.use(
    (res) => {
      if(res?.data?.accessToken){
       dispatch(accessToken(res?.data?.accessToken))
      }
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/signin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          // originalConfig._retry = true;

          try {
            // const rs = await instance2.post("/auth/refreshtoken", {
            //   refreshToken: tokenService.action.getLocalRefreshToken(),
            // });

            // const { accessToken } = rs.data;
            // dispatch(auth?.authLogout());
            // tokenService.action.updateLocalAccessToken(accessToken);
            const logout = getLogoutFunction();
            if (logout) {
              logout(); // Call the logout function if available
            }
            // return instance2(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;






