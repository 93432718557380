import { Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, Select, styled, Switch, TextField, Typography } from "@mui/material";
import Style from "./style";
import { AgenciICon, Avatar, CheckFilled, InactiveIcon, PlanTag, ProDimond, TrialIcon } from "../../../assets/svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setActiveFilterHeader } from "../../../store/reducers/uiReducer";
import { getAgenciesById, getAgencyDeatil } from "../../../store/actions/agenciesAction";
import TrialDyas from "./trialDays";
import { fetchPlanList, planMembers, upgradePlan } from "../../../store/actions/planAction";

const PlanChange = (props) => {
    const { className } = props;
    const { list } = useSelector((state) => state.agencies);
    const { agency_id } = useParams()
    const dispatch = useDispatch()
    const [planStatus, setPlanStatus] = useState("")
    const { list: planList } = useSelector((state) => state.plan)
    const [selectedPlan, setSelectedPlan] = useState('');
    const [planId, setplanId] = useState('');
    const [priceId, setpriceId] = useState('');
    const [trialDate, setTrialDate] = useState(null);
    const [trialDays, setTrialDays] = useState(null)
    const [trialPeriod, setTrialPeriod] = useState(null)
    const [isResetBillingCycle, setIsResetBillingCycle] = useState(false);
    useEffect(() => {
        dispatch(getAgenciesById(agency_id))
        dispatch(setActiveFilterHeader())
        dispatch(planMembers({ agency_id: agency_id }))
    }, [agency_id])

    useEffect(() => {
        getPlanDetails();
    }, [agency_id, list]);

    useEffect(() => {
        getPlans();
    }, [dispatch]);

    useEffect(() => {
        if (list?.agencyDetails?.stripe_id && list?.agencyDetails?.sub_id) {
            dispatch(getAgencyDeatil({ id: agency_id, detail: "billingInvoices", stripe_id: list?.agencyDetails?.stripe_id ? list?.agencyDetails?.stripe_id : "", subscription_id: list?.agencyDetails?.sub_id ? list?.agencyDetails?.sub_id : "" }))
        }

    }, [list?.agencyDetails?.sub_id])

    const getPlans = () => {
        dispatch(fetchPlanList(agency_id))
    }
    const save_update = () => {
        const formattedTrialDate = trialDate ? new Date(trialDate).toLocaleDateString('en-CA') : null;
        const isCreateSubscription = !list?.agencyDetails?.sub_id;
        dispatch(upgradePlan({
            cb: getAgenciesById,
            agency_id: parseInt(agency_id),
            planId: planId,
            planMembers: planList?.planMembers?.planMembers,
            trialDate: formattedTrialDate,
            subscription_id: list?.agencyDetails?.sub_id,
            resetBilling: isResetBillingCycle,
            customerId: list?.agencyDetails?.stripe_id,
            trialPeriodDays: trialDays,
            priceId: priceId,
            isCreateSubscription:isCreateSubscription

        },))
        setTrialDate("")
        setIsResetBillingCycle(false);

    }
    const getPlanDetails = () => {
        setPlanStatus("");

        if (list?.agencyDetails) {
            const { status, subscriptionDetails } = list.agencyDetails;

            if (status === "Cancelled" && subscriptionDetails) {
                const date = new Date(subscriptionDetails.cancel_at * 1000);
                const formattedDate = date.toLocaleDateString();

                if (subscriptionDetails.cancel_at_period_end) {
                    setPlanStatus(`Cancel at current period end ${formattedDate}`);
                } else if (subscriptionDetails.status === "canceled") {
                    setPlanStatus("Cancelled");
                } else {
                    setPlanStatus(`Cancel at ${formattedDate}`);
                }
            } else if (status === "Trial") {
                setPlanStatus("Trial");
            } else if (status === "Active") {
                setPlanStatus("Active");
            }
        }
    };

    return (
        <Grid container className={className}>
            <Grid item xs={12} className={`agenciDetailCard`}>
                <Grid container spacing={2} justifyContent={"space-between"}>
                    <Grid item>
                        <Grid item className="dflex aStart">
                            <Grid item className="agenciName">
                                <Grid>
                                    <Avatar />
                                </Grid>
                                <Grid sx={{ ml: 2 }}>
                                    <Typography
                                        variant="h4"
                                        className="fw-600"
                                        sx={{ mb: 1 }}
                                        color={'primaryText'}
                                    >
                                        {list?.agencyDetails?.agency_name}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className="dflex"
                                        color={"text.greyText2"}
                                    >
                                        <AgenciICon />
                                        <Grid sx={{ ml: 1 }}>Substance Digital Branding</Grid>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ ml: 3 }}>
                                <Grid className={`${list?.agencyDetails?.status === "Trial" ? "agenciTrail" : list?.agencyDetails?.status === "Active" ? "agenciStatusActive" : "agenciInactive"} chipItem`}>
                                    {list?.agencyDetails?.status === "Trial" ? <TrialIcon /> : list?.agencyDetails?.status === "Active" ? <CheckFilled /> : <InactiveIcon />}
                                    <Typography
                                        sx={{ ml: 1 }}
                                        variant="body2"
                                        color={'text.primaryText'}
                                    >
                                        {planStatus}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className="planCard">

                        <Grid item className="agenciPlan chipItem">
                            <ProDimond />
                            <Grid className="dflex aBaseline">
                                <Typography
                                    variant="h1"
                                    color={"text.primaryText"}
                                    className="fw-700 ml-3"              >
                                    ${list?.agencyDetails?.price}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color={"text.primaryText"}
                                    className="pl5"
                                >
                                    USD/Month
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* PLan details*/}
            <Grid item xs={12} className="planDetails">
                {/* Top section with Plan Details header and Save & Update button */}
                <Grid container justifyContent="space-between" alignItems="center">
                    <Typography className="header">Plan Details</Typography>
                    <Button variant="contained" onClick={save_update}>
                        {list?.agencyDetails?.sub_id ? "Save and Update" : "Create Subscription"}
                    </Button>
                </Grid>

                {/* Content section with labels and answers in left and right columns */}
                <Grid item xs={12} container spacing={4} mt={2}>
                    {/* Left Column */}
                    <Grid item xs={6} container spacing={2}>
                        {/* Left Column Labels */}
                        <Grid item xs={6} container direction="column" spacing={2}>
                            <Grid item>
                                <Typography className="textForm">Started Date:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textForm">Current Period Date:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textForm">Mail ID:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textForm">Next Invoice Date:</Typography>
                            </Grid>
                        </Grid>
                        {/* Left Column Answers */}
                        <Grid item xs={6} container direction="column" spacing={2}>
                            <Grid item>
                                <Typography className="textforms">{list?.results?.[0]?.created_on || "-"}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textforms">{list?.results?.[0]?.period || "-"}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textforms">{list?.agencyDetails?.agency_email || "-"}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textforms">{list?.results?.[0]?.next_bill || "-"}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={6} container spacing={2}>
                        {/* Right Column Labels */}
                        <Grid item xs={6} container direction="column" spacing={2}>
                            <Grid item>
                                <Typography className="textForm">Referral Code:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textForm">Discount:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textForm">Coupon Code:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textForm">Next Invoice Amount:</Typography>
                            </Grid>
                        </Grid>
                        {/* Right Column Answers */}
                        <Grid item xs={6} container direction="column" spacing={2}>
                            <Grid item>
                                <Typography className="textforms">{list?.agencyDetails?.referral_code || "-"}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textforms">{list?.agencyDetails?.discount || "-"}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textforms">{list?.agencyDetails?.coupon || "-"}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography className="textforms">${list?.agencyDetails?.price}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%', mt: 2 }} />
                <Grid sx={{ mt: 2 }}>
                    <Typography className="header">Billing cycle details</Typography>
                </Grid>
                {/* <Divider sx={{ width: '100%', mt: 2 }} /> */}
                {/* <Grid sx={{ mt: 2 }}>
                    <Typography className="header">Duration</Typography>
                    <Grid item>
                        <Typography className="textForm">12 Jun 2024</Typography>
                    </Grid>
                </Grid> */}
                <Grid sx={{ mt: 4 }}>
                    <Typography className="header">Pricing</Typography>
                </Grid>
                <Grid>
                    <Divider sx={{ width: '100%', mt: 2 }} />
                </Grid>
                <Grid sx={{ mt: 2 }} className="pricing">
                    <Typography>PRODUCT</Typography>
                    <Typography>QTY</Typography>
                    <Typography>TOTAL</Typography>
                </Grid>
                <Grid>
                    <Divider sx={{ width: '100%', mt: 2 }} />
                </Grid>
                <Grid sx={{ mt: 2 }} className="pricing">
                    {list?.agencyDetails?.sub_id ? (
                        <>
                            <Typography>{list?.agencyDetails?.plan_name} (${list?.agencyDetails?.price})</Typography>
                            <Typography>1</Typography>
                            <Typography>${list?.agencyDetails?.price} USD/month</Typography>
                        </>
                    ) : <></>}
                </Grid>
                <Grid>
                    <Divider sx={{ width: '100%', mt: 2 }} />
                </Grid>
                <Grid sx={{ mt: 1 }} container className="addproducts">
                    <Grid item xs={6}>
                        <Autocomplete
                            options={planList?.plans || []}
                            getOptionLabel={(option) => option.plan_name && option.price ? `${option.plan_name} - $${option.price}` : ''}
                            value={selectedPlan}
                            onChange={(event, newValue) => {
                                setSelectedPlan(newValue);
                                setplanId(newValue ? newValue.id : null);
                                setpriceId(newValue ? newValue.stripe_plan_id : null)
                                setTrialPeriod(newValue ? newValue.trial_period : null)
                            }

                            }
                            renderInput={(params) => (
                                <TextField className="planlistnames" {...params} placeholder="Select a plan" />
                            )}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className="coupons" xs={6}>
                        <Grid className="taxes">
                            <Button className="couponbutton">Add coupon</Button>
                            <FormControlLabel control={<Switch />} label="Collect tax automatically" />
                            <Button className="couponbutton">Add tax manually </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <Typography className="header">Billing cycle details</Typography>
                    <Grid>
                        <FormControlLabel control={<Checkbox checked={isResetBillingCycle}
                            onChange={(e) => setIsResetBillingCycle(e.target.checked)} />} label="Reset Billing cycle" />
                        <Typography className="textForm">This will generate an invoice immediately</Typography>
                    </Grid>
                </Grid>
                {/* <Grid sx={{ mt: 2 }}>
                    <Typography className="header">Proration behavior</Typography>
                    <Grid className="coupons">
                        <FormControlLabel control={<Switch />} label="Prorate changes" />
                        <FormControlLabel control={<Radio />} label="Incorporate the proration cost in the next billing cycle" />
                        <FormControlLabel control={<Radio />} label="Bill the prorated amount immediately" />
                    </Grid>
                </Grid> */}
                <Grid sx={{ mt: 2 }}>
                    <Typography className="header">Free trial days</Typography>
                    <Grid sx={{ mt: 2 }}>
                        <TrialDyas setTrialDate={setTrialDate} setTrialDays={setTrialDays} setTrialPeriod={trialPeriod} />
                        {/* <DatePickers/> */}
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%', mt: 2 }} />
                <Grid sx={{ mt: 2 }}>
                    <Typography className="header">Subscription settings</Typography>
                    <Grid>
                        <Typography sx={{ mt: 1 }} className="textForm">These Settings Apply Globally Across the Subscription and All Phases</Typography>
                    </Grid>
                    <Grid className="coupons">
                        <FormControlLabel control={<Radio />} label="Automatically charge a payment method on file" />
                        <FormControlLabel control={<Radio />} label="Email invoice to the customer to pay manually" />
                    </Grid>
                </Grid>
                <Grid sx={{ mt: 2 }}>
                    <Typography className="header">Payment due</Typography>
                    <TextField sx={{ mt: 1 }} placeholder="select days"></TextField>
                </Grid>
                <Divider sx={{ width: '100%', mt: 2 }} />
                <Grid sx={{ mt: 2 }}>
                    <Typography className="header">Activity</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};


export default styled(PlanChange)(Style);
