import React from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  styled,
} from "@mui/material";
import Style from "./Style";
import { PaidAgencies, TotalAgencies, TotalUsers, TrialAgencies } from "../../assets/svg";
import CountCard from "../../components/CountCard";

const AgencyDashbard = (props) => {
  const { className } = props;

  //topCard
  const countdata = [
    {
      name: 'Total Business',
      count: '1290',
      icon: <TotalAgencies />
    },
    {
      name: 'Number of Paid Business',
      count: '2321',
      icon: <PaidAgencies />
    },
    {
      name: 'Number of Trial Business',
      count: '1232',
      icon: <TrialAgencies />
    },
    {
      name: 'Total Number of Users',
      count: '123K',
      icon: <TotalUsers />
    },
  ]

  return (
    <Grid
      container
      alignItems={"center"}
      className={className}
      spacing={3}
    >
          <CountCard
          data={countdata}
          />
    </Grid>
  );
};

AgencyDashbard.defaultProps = {
  classes: {}
};

AgencyDashbard.propTypes = {
  classes: PropTypes.object
};

export default styled(AgencyDashbard)(Style);
