
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: null,

  loading:null
};

const emailEditorSlice = createSlice({
  name: 'emailEditor',
  initialState,
  reducers: {
    setEditorContent: (state, action) => {
      state.content = action.payload;
     
    },
  },
});

export const { setEditorContent } = emailEditorSlice.actions;

export default emailEditorSlice.reducer;
