const Style = () => ({
    
    totalsdetails:{
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline",
        gap: "10px",
    }
    }
)

export default Style;