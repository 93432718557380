import { z } from 'zod';

const createProfileSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().min(1, "Email is required").email("Invalid email format"),
  last_name:z.string().default(""),
  department:z.string().default(""),
  phone:z.string().default(""),
  employee_id:z.string().default(""),
  secondary_phone_number:z.string().default(""),
  personal_email:z.string().default(""),

});

export default createProfileSchema;
