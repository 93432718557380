import { Autocomplete, Avatar, Button, Checkbox, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Select, styled, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Style from '../style';
import { ButtonPlusIcon, CheckedIcon, CloseBlueIcon, CloseIcon, DragIcon, UnCheckedIcon } from '../../../../assets/svg';

function AssetSettings(props) {

    const { className } = props;

    return (
        <Grid item xs={12} className={className} sx={{ pb: 2 }}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Import Assets</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>Name of the feature and status be mentioned here</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Search Workspace / Owner</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Grid container justifyContent={"space-between"} alignItems={"center"} className='assetWorkspaceItem'>
                                <Grid item className='flexAlign'>
                                    <Avatar sx={{ height: '17px', width: '17px' }} />
                                    <Typography variant='body1' className='fw-500' sx={{ pl: 0.5, pr: 0.5 }}>Cat Products & Services </Typography>
                                </Grid>
                                <Grid item className='flexAlign'>
                                    <Typography variant='body1' color={"textSecondary"} className='fs-12'>Owner : </Typography>
                                    <Avatar sx={{ height: '20px', width: '20px', ml: 0.5, mr: 0.5 }} />
                                    <Typography variant='body1' className='fs-12'>Kennly Cube Henry</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant='outlined' className='blueBtn' fullWidth sx={{ height: '40px' }}>Change</Button>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Schedule Timing</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>The description and tags of the above feature for understanding be mentioned here. </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Scheduled Time</Typography>
                            <Select size='small' fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Select Timezone</Typography>
                            <Select size='small' fullWidth />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Scheduled Days</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>Name of the feature and status be mentioned here</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField size='small' fullWidth InputProps={{
                                endAdornment: <InputAdornment position="end"><Typography variant='body2' color={"text.greyText4"}>Days Added</Typography></InputAdornment>,
                            }} />
                        </Grid>
                        <Grid item>
                            <Button variant='contained' startIcon={<ButtonPlusIcon />} sx={{ height: '42px' }}>Add</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600 flexAlign' sx={{ pb: 1 }}>Day <Typography variant='h4' sx={{pl:1}} color={"text.blueText"}>1</Typography></Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>The description and tags of the above feature for understanding be mentioned here. </Typography>

                    <Grid className='removeBtn' sx={{mt:3}}>Remove</Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Select Date</Typography>
                            <TextField size='small' fullWidth />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1, pt: 2 }}>Add Assets</Typography>

                            {/* loop */}
                            <Grid container sx={{ mb: 1 }}>
                                <FormControlLabel
                                    sx={{ minWidth: '100px' }}
                                    control={
                                        <Checkbox
                                            icon={<UnCheckedIcon />}
                                            checkedIcon={<CheckedIcon />}
                                        // name={filter.key}
                                        // value={item.value}
                                        // checked={selectedFilters[filter?.key]?.includes(item.value)}
                                        // onChange={(e) => handleCheckboxChange(e, filter.key)}
                                        />
                                    }
                                    label={"Flows"}
                                />
                                <Grid item xs={6}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        size='small'
                                        // options={top100Films}
                                        sx={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Search Here" />}
                                    />
                                </Grid>
                            </Grid>
                            {/* loop */}

                            {/* Asset items */}
                            <Grid container spacing={2} sx={{mt:'-6px'}}>

                                {/* loop */}
                                <Grid item xs={12} md={4}>
                                    <Grid container justifyContent={"space-between"} className='assetDragItem' wrap='nowrap'>
                                        <Grid item className='flexAlign'>
                                            <IconButton sx={{mr:1, p:0}}><DragIcon /></IconButton>
                                            <Avatar sx={{ height: '17px', width: '17px' }} />
                                            <Typography variant='body1' className='fw-500' sx={{ pl: 0.5, pr: 0.5, maxWidth: 'calc(100% - 35px)' }} noWrap>Cat Products & Services </Typography>
                                        </Grid>
                                        <IconButton sx={{p:0.5}}><CloseBlueIcon /></IconButton>
                                    </Grid>
                                </Grid>
                                {/* loop */}

                            </Grid>
                            {/* Asset items */}


                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 2, mb: 2 }} />


        </Grid>
    )
}

AssetSettings.defaultProps = {
    classes: {}
};

AssetSettings.propTypes = {
    className: PropTypes.string,
};


export default styled(AssetSettings)(Style);
