import { Button, Grid, InputAdornment, styled, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Style from './style';
import { Filter, SearchIcon } from '../../../assets/svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CardComponent from '../../../components/CardComponent';

function MonetiseDetail(props) {

    const { className } = props;

    return (
        <Grid item xs={12} className={`${className}`} sx={{ pb: 2 }}>
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ pb: 2 }}>
                <Grid className='flexAlign'>
                    <Typography variant='h6' className='fw-700' color={"textSecondary"}>Campaign Details</Typography>
                    <Grid component={"span"} sx={{mr:1, ml:1}} className='flexAlign'><ChevronRightIcon /></Grid>
                    <Typography variant='h6' className='fw-700'>Campaign Details</Typography>
                </Grid>
                <Grid className='flexAlign searchBox'>
                    <TextField size='small' sx={{ mr: 1 }} placeholder='search here...' InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }} />
                    <Button variant='contained' className='filterIcon' startIcon={<Filter />}>Filter</Button>
                </Grid>
            </Grid>

            <CardComponent>
                Table
            </CardComponent>
        </Grid>
    )
}

MonetiseDetail.defaultProps = {
    classes: {}
};

MonetiseDetail.propTypes = {
    className: PropTypes.string,
};


export default styled(MonetiseDetail)(Style);
