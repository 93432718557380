import { Avatar, Button, Divider, Grid, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react'
import Style from '../style';
import { CalendarIcon, ClockIcon, ExportIcon2, GlobeIcon } from '../../../../assets/svg';

function Confirmation(props) {

    const { className } = props;

    return (
        <Grid item xs={12} className={className}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Campaign Summary</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>Name of the feature and status be mentioned here</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Campaign Name</Typography>
                            <Typography variant='body1' className='fw-500'>Black Friday Schedule</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Start Date</Typography>
                            <Typography variant='body1' className='fw-500 flexAlign' sx={{ pb: 1 }}><Grid component={"span"} className='flexAlign' sx={{ mr: 1 }}><CalendarIcon /></Grid>Thu, Nov 19, 2023</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button variant='text' endIcon={<ExportIcon2 />}>Copy Link</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 4, mb: 4 }} />

            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Workspace Details</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>Name of the feature and status be mentioned here</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Workspace Name</Typography>
                            <Grid className='flexAlign'>
                                <Avatar sx={{ height: '32px', width: '32px' }} />
                                <Typography variant='body1' className='fw-500' sx={{ ml: 1 }}>Black Friday Schedule</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Scheduled Time</Typography>
                            <Typography variant='body1' className='fw-500 flexAlign' sx={{ pb: 1 }}><Grid component={"span"} className='flexAlign' sx={{ mr: 1 }}><ClockIcon /></Grid>Thu, Nov 19, 2023</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1' color={"text.greyText3"} className='fw-500' sx={{ pb: 1 }}>Scheduled Time</Typography>
                            <Typography variant='body1' className='fw-500 flexAlign' sx={{ pb: 1 }}><Grid component={"span"} className='flexAlign' sx={{ mr: 1 }}><GlobeIcon /></Grid>China, Singapore...</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 4, mb: 4 }} />

            <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                    <Typography variant='body1' className='fw-600' sx={{ pb: 1 }}>Days Scheduled</Typography>
                    <Typography variant='body1' color={"text.greyText4"} className='fs-12 fw-500' sx={{ pb: 1 }}>Name of the feature and status be mentioned here</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>

                        {/* loop */}
                        <Grid item xs={12} md={2}>
                            <Grid className='dayItem'>
                                <Typography variant='body1' color={"textSecondary"} className='fw-500' sx={{ pb: 2 }} align='center'>Day 1</Typography>
                                <Typography variant='body1' color={"textSecondary"} className='fw-500 fs-12' sx={{ pb: 1 }} align='center'>12 Assets</Typography>
                            </Grid>
                        </Grid>
                        {/* loop */}

                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

Confirmation.defaultProps = {
    classes: {}
};

Confirmation.propTypes = {
    className: PropTypes.string,
};


export default styled(Confirmation)(Style);
