import { styled } from '@mui/material';

const Style = ()=>({
    dialogPaper: {
      padding: '20px',
      borderRadius: '12px',
      maxWidth: '600px',
      width: '100%',
      backgroundColor: 'red',
    },
    dialogTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      color: '#333',
      textAlign: 'center',
      marginBottom: '20px',
    },
    dialogContent: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    textField: {
      marginBottom: '10px',
    },
    formControl: {
      marginBottom: '10px',
      width: '100%',
    },
    submitButton: {
      backgroundColor: '#1976d2',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#155a9c',
      },
      marginTop: '20px',
      width: '100%',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
    },

    '.headerContainer': {
      borderBottom: '1px solid #EAEAEA',
      paddingBottom: '12px',
      marginBottom: '16px'
    },
    '.formContainer': {
      border: '1px solid #EAEAEA',
      padding: '16px',
      borderRadius: '12px',
      boxShadow: '0px 0px 4px 0px #0000000F',
     '.MuiInputLabel-formControl': {
        top: '-5px',
        '&.MuiInputLabel-shrink': {
          display: 'none'
        }
      }
    }
  })

export default Style;
