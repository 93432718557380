import React, { useEffect, useState } from 'react';
import { Grid, Typography, styled, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import CustomInput from '../../components/TextField';
import profileStyles from './style';
import LogoInput from '../../components/LogoInput';
import CustomSelect from '../../components/InputSelect';
import { useNavigate, useParams } from 'react-router-dom';
import FormHeader from '../Addons/components/CreateAddonHeader/formHeader';
import { updateUserProfileAction, userProfile as getuserProfile, } from '../../store/actions/userProfileAction';
import { zodResolver } from '@hookform/resolvers/zod';
import createProfileSchema from './validation/userProfileValidation';


const UserProfile = (props, classes) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { className, } = props;
    const { id } = useParams()
 
 
    useEffect(() => {

        dispatch(getuserProfile())
    }, [])
    const selectedUser = useSelector((state) => state.profileUser.list.profile);
    const {
        name,
        email,
        department,
        phone,
        employee_id,
        personal_email,
        secondary_phone_number,
        last_name
    } = selectedUser;
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: name || '',
            last_name: last_name || "",
            email: email || "",
            department: department || "",
            phone: phone || "",
            employee_id: employee_id || "",
            personal_email: personal_email || "",
            secondary_phone_number: secondary_phone_number || "",

        },
        
        resolver: zodResolver(createProfileSchema),
    });
    const [imageType, setImageType] = useState(null)

    const [logo, setLogo] = useState(null)
    const [newImageUrl,setNewImageUrl] = useState("")
       
    useEffect(() => {
        if (selectedUser) {
            setValue('name', selectedUser.name || '');
            setValue('last_name', selectedUser.last_name || '');
            setValue('email', selectedUser.email || '');
            setValue('department', selectedUser.department || '');
            setValue('phone', selectedUser.phone || '');
            setValue('employee_id', selectedUser.employee_id || '');
            setValue('personal_email', selectedUser.personal_email || '');
            setValue('secondary_phone_number', selectedUser.secondary_phone_number || '');
            setValue('image_url', selectedUser.image_url || ''); // Set image_url if needed
        }
    }, [selectedUser, setValue]);
    
   
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const base64String = e.target.result.split(',')[1]; // Get the base64 part
                const truncatedBase64String = base64String.substring(0, 200); // Limit to 500 characters
                const imageMimeType = file.type;
                const iamgeName=file.name
                setLogo(e.target.result);
                setNewImageUrl("")
                setImageType({
                    base64Image: base64String,
                    imageMimeType: imageMimeType,
                    iamgeName:iamgeName
                });
             

            };
            reader.readAsDataURL(file);
          
        }

    };
    useEffect(()=>{
        setNewImageUrl(selectedUser?.image_url)
    },[dispatch])
    const onSubmit = (data) => {
       
        const formattedData = {
            updateData: {
                name: data.name || '',
                email: data.email || '',
                last_name:data.last_name || '',
                 department: data.department || '',
                phone: data.phone || '',
                employee_id: data.employee_id || '',
                personal_email: data.personal_email || '',
                secondary_phone_number: data.secondary_phone_number || '',
                image_url:imageType,
                image_new_url:newImageUrl
            },
        };
        dispatch(updateUserProfileAction(formattedData))
     
        //dispatch(getuserProfile())
        //window.location.reload();
    };
    const handleCancel = () => {
        // Reset form values to the original data (initial values)
        reset({
          name,
          last_name,
          email,
          department,
          phone,
          employee_id, 
          personal_email,
          secondary_phone_number,
        });
      };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid className={`${className} createFeatureForm`}>
                <Grid>
                    <FormHeader title={'User Profile'}  cancelAction={handleCancel}/>
                </Grid>
                <Grid className='profile_container'>
                    <Grid className='profile_add_details'>
                        <Grid style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                            <Typography variant='h6' className='user_profile pb5'>User Profile</Typography>
                            <Controller
                                name="productImage"
                                control={control}
                                render={({ field }) => <LogoInput {...field} onChange={handleImageChange} logo={logo ||selectedUser?.image_url} />}
                            />
                            
                        </Grid>
                        <Grid style={{ width: "100%" }}>
                            <Grid className='plan-details'>
                                <Grid className='profile-detail' style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>First Name</Typography>
                                    <Controller
                                        name="name"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Name" defaultValue={name} />}
                                    />
                                
                                      {errors.name && <span className='error_text'>{errors.name.message}</span>}
                                </Grid>
                                <Grid className='profile-detail' style={{ width: "100%", display: "flex", flexDirection: "column", }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Last Name</Typography>
                                    <Controller
                                        name="last_name"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Name" defaultValue={last_name}/>}
                                    />
                                </Grid>
                            </Grid>

                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Workspace Email ID </Typography>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Email"  defaultValue={email}/>}
                                    />
              
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Phone</Typography>
                                    <Controller
                                        name="phone"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Click here to enter" defaultValue={phone}/>}
                                    />
                                </Grid>
                            </Grid>
                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Designation</Typography>
                                    <Controller
                                        name="department"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Designation" defaultValue={department}/>}
                                    />
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Employee ID</Typography>
                                    <Controller
                                        name="employee_id"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Employee ID" defaultValue={employee_id}/>}
                                    />
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>
                    {/* <Grid className='profile-end'></Grid>

                    <Grid className='profile_description'>
                        <Grid className='licence-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>User Role</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>Can edit and select the user role group for the above user, also custom the role access. </Typography>

                        </Grid>
                        <Grid className='profile-detail' style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            <Controller
                                name="employee_id"
                                control={control}
                                render={({ field }) => <CustomInput {...field} placeholder="Enter Employee ID" />}
                            />
                        </Grid>
                        <Grid style={{ opacity: 0 }}>

                        </Grid>
                    </Grid> */}

                    <Grid className='profile-end'></Grid>
                    <Grid className='pricing_details'>
                        <Grid className='profile-detail'>
                            <Typography variant='h5' style={{ fontWeight: "600", paddingBottom: "5px" }}>User Personal Info</Typography>
                            <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", width: "80%", color: "#73757A" }}>The above user was added by the following person into the sales dashboard. </Typography>

                        </Grid>
                        <Grid style={{ width: "100%" }}>
                            <Grid className='plan-details'>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Personal Email</Typography>
                                    <Controller
                                        name="personal_email"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Personal Email" defaultValue={personal_email}/>}
                                    />
                                </Grid>
                                <Grid style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <Typography variant='h5' style={{ fontWeight: "500", paddingBottom: "5px", color: "#64748B" }}>Secondary Phone</Typography>
                                    <Controller
                                        name="secondary_phone_number"
                                        control={control}
                                        render={({ field }) => <CustomInput {...field} placeholder="Enter Secondary Phone" defaultValue={secondary_phone_number}/>}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </form>
    );
                                  
};

export default styled(UserProfile)(profileStyles);
