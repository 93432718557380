import  instance1 from "../../utils/api1";
import instance3 from "../../utils/sweetp_api";

export const agenciesService = {
  getAgencies,
  getAgencyById,
  getAgencyDeatil,
  getAllAgencyDetail,
  updateagencyEmail,
  existingmailVerify,
  otpgenerate,
  otpverify,
  resendotp,
  updateStripe,
  cloneSpecialFlow,
  deleteAgencyById
};
//!important  while creating new service add type as module and subtype as submodule for access value
async function getAgencies(data) {
  return await instance1.post(`agency/getAll`,data);
}

async function getAgencyById(param) {
  return await instance1.get(`agency/${param}`)
}

async function getAgencyDeatil(id, detail,stripe_id,subscription_id, data) {
  return await instance1.post(`agency/agencyClient?id=${id}&detail=${detail}&stripe_id=${stripe_id}&subscription_id=${subscription_id}`, data);
}
async function getAllAgencyDetail(param = "") {
  return await instance1.get(`agency/getAllAgencyList?agencySearch=${param}`)
}

async function updateagencyEmail(data) {
  return await instance1.post(`agency/updateEmail`,data);
}

async function existingmailVerify(data) {
  return await instance1.post(`agency/agencymailverify`,data);
}

async function otpgenerate(data) {
  return await instance1.post(`agency/agencyotpgenerate`,data);
}

async function otpverify(data) {
  return await instance1.post(`agency/agencyotpverify`,data);
}

async function resendotp(data) {
  return await instance1.post(`agency/agencyresendotp`,data);
}

async function updateStripe(data) {
  return await instance1.post(`agency/updatestripe`,data);
}

async function cloneSpecialFlow(data) {
  return await instance3.post(`test/cloneSpecialFlows`,data);
}
async function deleteAgencyById(param) {
  return await instance1.delete(`agency/delete/${param}`)
}