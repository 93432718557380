import  instance1 from "../../utils/api1";

export const planService = {
    planList,
    updatePlan,
    planMembers
}

async function planList(data) {
    return await instance1.post(`plandetails/planList`,data);
  }

  async function updatePlan(data) {
    return await instance1.post(`plandetails/updateplan`,data);
  }
  
  async function planMembers(data) {
    return await instance1.post(`plandetails/planmembers`,data);
  }