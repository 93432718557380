import React from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';

const LineChart = ({ data = {}, startDate, endDate, style = { height: 400, width: '100%' } }) => {

  const generateDateRange = (start, end) => {
    const dates = [];
    let currentDate = moment(start);
    while (currentDate.isSameOrBefore(end)) {
      dates.push(currentDate.format('DD MMM YY'));
      currentDate = currentDate.add(1, 'day');
    }
    return dates;
  };

  const xAxisDates = generateDateRange(startDate, endDate);

   const paidAgenciesCountMap = xAxisDates.reduce((acc, date) => {
    acc[date] = 0;
    return acc;
  }, {});

  const paidAgencies = data.trialAgenciesCount?.overallPaidAgencies || [];
  if (Array.isArray(paidAgencies)) {
    paidAgencies.forEach((agency) => {
      const agencyDate = moment(agency.created_on).format('DD MMM YY');
      if (paidAgenciesCountMap.hasOwnProperty(agencyDate)) {
        paidAgenciesCountMap[agencyDate] += 1;
      }
    });
  } else {
    console.warn("data.trialAgenciesCount.overallPaidAgencies is undefined or not an array.");
  }

   const paidAgenciesData = xAxisDates.map(date => paidAgenciesCountMap[date]);

  const maxCount = Math.max(...paidAgenciesData) || 10;

  const chartOptions = {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const { name, value } = params[0];
        return `${name}: <span style="color: #2463eb;">${value}</span> Paid Agencies`;
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisDates,
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: maxCount + 5,
      interval: maxCount >= 20 ? Math.ceil(maxCount / 3) : 5,
      axisLabel: {
        formatter: '{value}',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#F1F5F9', 
        },
      },
    },
    series: [
      {
        name: 'Paid Agencies Count',
        data: paidAgenciesData,
        type: 'line',
        smooth: true,
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#aec7ff', 
              },
              {
                offset: 1,
                color: '#eeeefd', 
              },
            ],
          },
        },
        lineStyle: {
          color: '#aec7ff',
        },
        symbol: 'circle', 
        symbolSize: 6, 
        itemStyle: {
          color: '#aec7ff', 
        },
      },
    ],
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100,
        xAxisIndex: 0,
        labelFormatter: '', 
      },
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
    ],
  };

  return (
    <ReactECharts
      option={chartOptions}
      style={style}
      notMerge={true}
      lazyUpdate={true}
    />
  );
};

export default LineChart;
