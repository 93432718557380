import React from "react";
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import Style from "./style";
import { BuildingIcon, CloseIcon, MailIcon, TickIcon, UserIcon } from "../../../assets/svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getuserinfo, otpverify, passwordreset, updateEmail, otpgenerate, mailverify ,resendOtp, deleteUserById} from "../../../store/actions/UserAction";
import { useEffect, useState } from "react";
import moment from 'moment';
import DialogComp from "../../../components/DialogComponent";
import { EditIcon2 } from "../../../assets/svg";
import DeleteConfirmation from "../../../components/DeleteConfirmation/deleteConfirmation";

const UserInfo = (props) => {
  const { className } = props;
  const navigate = useNavigate();
  const { list } = useSelector((state) => state.userinfo);
  const { userInfo, ownedAgencies, workspace, reset } = list;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [agencyCount, setAgencyCount] = useState(6);
  const [workspaceCount, setWorkspaceCount] = useState(6);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogOpen, setdialogOpen] = useState(false);
  const [verifyotp, setVerifyotp] = useState(false);
  const [editProfile, seteditProfile] = useState(false)
  const [mailVerify,setmailVerify] = useState(false)
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [username, setusername] = useState(userInfo.username)
  const [email, setEmail] = useState(userInfo.email)
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [openDelete, setOpenDelete] = React.useState(false);
  const role = userInfo?.user_role ? JSON.parse(userInfo?.user_role) : {};

  useEffect(() => {
    dispatch(getuserinfo({ id }))
  }, [dispatch, id])


  useEffect(() => {
    setusername(userInfo.username);
    setEmail(userInfo.email)
  }, [userInfo]);

  

  useEffect(() => {
    if (!isResendEnabled) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setIsResendEnabled(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isResendEnabled]);

  const handlePasswordReset = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      dispatch(passwordreset({ email: userInfo.email, name: userInfo.name }));
    }
  };

  const handlecancel = () => {
    setdialogOpen(false)
  }
  function handleverifymail() {
    setmailVerify(true)
  }
  const handleEmailchange = () => {
    setdialogOpen(true)
    dispatch(mailverify({email:email , cb:()=> {
      handleverifymail()
    }}))
  }

  const handleCloseDialog = () => {
    setdialogOpen(false);
    setmailVerify(false);
    setVerifyotp(false);
  };

  const handleUpdateEmail = (confirm) => {
    setdialogOpen(false)
    if (confirm) {
      dispatch(updateEmail({ email: userInfo.email, newEmail: email, ID: parseInt(id), name: userInfo.name }))
    }
  }

  function handleCloseDialogFun(){
    setdialogOpen(false)
  }

  const handleOtpverify = (confirm) => {
    if (confirm) {
      setVerifyotp(true)
      dispatch(otpverify({ email: userInfo.email, newEmail: email, ID: parseInt(id), name: userInfo.name, otp: otp.join('') ,cb:()=>{
        handleCloseDialogFun()
      }}))
    }
  }
  const handleotpgenerate = (confirm) => {
    if (confirm) {
      setVerifyotp(true)
      dispatch(otpgenerate({ email: userInfo.email, newEmail: email, ID: parseInt(id), name: userInfo.name, otp: otp.join('') }))
    }
  }
const handleproceed = () => {
  setmailVerify(false)
}
  const resendotp = (confirm) => {
    if(confirm) {
      dispatch(resendOtp({ email: userInfo.email, newEmail: email, ID: parseInt(id), name: userInfo.name }))
      setIsResendEnabled(false);
    setCountdown(60);
    }
  }

  const handleeditProfile = () => {
    seteditProfile(true)
  }
  const handleReset = () => {
    seteditProfile(false)
  }

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.replace(/\D/g, '');
    setOtp(newOtp);

 
    if (value && index < 5) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData('text').slice(0, 6); 
    const digits = pastedData.split('').map(d => d.replace(/\D/g, '')); 

    const newOtp = [...otp];
    digits.forEach((digit, i) => {
      if (i < newOtp.length) {
        newOtp[i] = digit; 
      }
    });

    setOtp(newOtp);

    // Focus the next empty input after pasting
    const nextEmptyIndex = newOtp.findIndex(d => !d);
    if (nextEmptyIndex !== -1) {
      document.getElementById(`otp-input-${nextEmptyIndex}`).focus();
    } else {
      document.getElementById(`otp-input-${digits.length - 1}`).focus();
    }
  };
  
  const joined_date = moment(userInfo.created_on ? userInfo.created_on : userInfo.modified_on).format('DD-MM-YYYY');
  const handleDeletePopUp = () => {
    setOpenDelete(true); 
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteConfirm = () => {
    setOpenDelete(false);
    dispatch(deleteUserById(id));
    navigate("/agencies/users");
   
  };
  return (
    <Grid
      container
      alignItems={"center"}
      className={className}
      style={{display:"flex",justifyContent:"end",gap:"10px"}}
    >
     <Button variant="contained" onClick={handleDeletePopUp} style={{ backgroundColor:'#FFDEDE' ,color:"#ff3b3b"}} >
          Delete
        </Button>
      <DialogComp openDialog={openDialog} handleDialogClose={handleDialogClose} userInfo={userInfo} />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: '507px',
            height: '228',
          },
        }}
      >
       <Grid display={"flex"} justifyContent={"space-between"}>
       <DialogTitle id="alert-dialog-title">
          {"Email Change Request"}
        </DialogTitle>
       <IconButton onClick={handleCloseDialog}> <CloseIcon/></IconButton>
       </Grid>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {mailVerify ? (
              <>
              <Typography>There is a existing Mail ID Do you like to delete this Credential and update?</Typography>
              </>
            ):
            <>
            {verifyotp ? (
              <>
                <Typography>Please enter OTP to verify</Typography>
                <Grid container spacing={1} justifyContent="center" sx={{ mt: 2 }}>
                  {otp.map((digit, index) => (
                    <Grid item key={index}>
                      <TextField
                        id={`otp-input-${index}`}
                        variant="outlined"
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={(e) => handlePaste(e, index)}
                        inputProps={{
                          maxLength: 1,
                        }}
                        sx={{ width: '40px', textAlign: 'center' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <Typography>
                Are you sure you want to change the email ID of the selected agency’s user?
              </Typography>
            )}
            </>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {mailVerify ? (
            <>
            <Button variant="contained" color="primary"onClick={handleproceed}>Proceed</Button>
            <Button variant="outlined" color="primary"  onClick={handlecancel}>Cancel</Button>
            </>
          ):
          <>
          {verifyotp ? (
            <>
              <Button variant="outlined" color="secondary" onClick={resendotp} disabled={!isResendEnabled}> {isResendEnabled ? 'Resend OTP' : `Resend OTP in ${countdown}s`}</Button>
              <Button variant="contained" color="primary" onClick={handleOtpverify}>Submit</Button>
            </>
          ) :
            <>
              <Button variant="outlined" color="primary" onClick={handleUpdateEmail}>Instant Update</Button>
              <Button variant="contained" color="primary" onClick={handleotpgenerate}>Verify & Update</Button>
            </>}
          </>}
        </DialogActions>
      </Dialog>
      <Grid item xs={12} className="userGroupContainer">
      
        <Grid>
          {editProfile ? (
            <>
              <Grid className="dflex aCenter jEnd mb-1">
                <Button
                  variant="text"
                  sx={{
                    fontSize: '10px',
                    fontWeight: '400px',
                    textDecoration: 'underline',
                    padding: '0'
                  }}
                  onClick={handleReset}>
                  Reset
                </Button>
              </Grid>
            </>
          ) :
            <Grid className="dflex aCenter jEnd mb-1">
              <Button
                variant="text"
                sx={{
                  fontSize: '10px',
                  fontWeight: '400px',
                  textDecoration: 'underline',
                  padding: '0'
                }}
                onClick={handleeditProfile}>
                Edit Profile
              </Button>
            </Grid>}
        </Grid>
        <Grid>
          <Grid className="dflex aCenter jEnd mb-1">
            <Typography variant="body2" className="fs-10" color={"text.greyText5"}>Joined Date :</Typography>
            <Typography variant="body2" className="fs-10 fw-500" color={"text.greyText4"}>{joined_date}</Typography>
          </Grid>
        </Grid>
        {/* user group name */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User Name</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>User name and the joined date in to the mentioned agency.</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container justifyContent={"space-between"}>
              <Grid item className="dflex aCenter">
                <Avatar src={userInfo.agency_logo} sx={{ width: 64, height: 64 }} />

                <Grid sx={{ ml: 2 }}>
                  <Typography variant="h4" className="mb-1">{userInfo.name}</Typography>
                  
                    <Typography variant="body2" className="dflex aCenter">
                      <UserIcon />
                      <span className="ml-1">{"@" + userInfo.username}</span>
                    </Typography>
                  

                </Grid>

                <Grid sx={{ ml: 4, mt: 4 }}>

                  {editProfile ? (
                    <>
                      <TextField
                        className="headerText"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <IconButton onClick={handleEmailchange}>
                              <TickIcon />
                            </IconButton>
                          )
                        }}
                      />
                    </>
                  ) : <>
                    <Typography variant="body2" className="dflex aCenter"><MailIcon />
                      <span className="ml-1">{userInfo.email}
                        <IconButton onClick={handleEmailchange}>
                          {/* <EditIcon2 /> */}
                        </IconButton>
                      </span>
                    </Typography>
                  </>}
                </Grid>
                <Grid className="Password_Reset">
                  {editProfile ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        className="reset_Button"
                        sx={{
                          ml: 2,
                          backgroundColor: '#2563EB',
                          border: '2px solid',
                          borderColor: '#2563EB',
                          fontSize: '0.875rem',
                          color: '#FFFFFF',
                          '&:hover': {
                            backgroundColor: '#D0E7FE',
                            borderColor: '#1E50C1',
                          },
                          position: 'relative',
                        }}
                        onClick={handlePasswordReset}>
                        Reset Password</Button>
                    </>
                  ) : <></>}

                </Grid>

              </Grid>

            </Grid>
          </Grid>
        </Grid>

        {/* user group name */}
        <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />

        {/* Owned Agencies */}
        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>Owned Agencies</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>These are the agencies owned by the above user</Typography>
          </Grid>
          {ownedAgencies?.length > 0 ?
            <>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>

                  {Object.entries(ownedAgencies).slice(0, agencyCount).map(([key, value]) => {
                    return (
                      <Grid item xs={12} md={4}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"} className="agenciesItem">
                          <Grid className="dflex aCenter">
                            <Avatar src={value.agency_logo} sx={{ width: 40, height: 40 }} />
                            <Grid className="pl-1">
                              <Typography variant="body2" className="fw-600">{value.agency_name}</Typography>
                              <Typography variant="body2" color={"text.greyText4"} className="dflex mt5 fs-10"><BuildingIcon /><span className="ml-1">{value.category}</span></Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>)
                  })}

                  {ownedAgencies?.length - 1 >= 6 ?
                    <Typography className="jEnd dflex primary1 w-100 cursorPointer" onClick={() => { agencyCount === 6 ? setAgencyCount(ownedAgencies?.length - 1) : setAgencyCount(6) }} > {agencyCount === 6 ? `Show more (${ownedAgencies?.length - 6})` : "Show Less"}</Typography>
                    : ""}
                </Grid>
              </Grid>
            </> : <Grid className="dflex aCenter"><Typography>No owned agencies</Typography></Grid>}
        </Grid>
        {/* Owned Agencies */}

        <Divider sx={{ mt: 5, mb: 5, width: '100%' }} />

        <Grid container>
          <Grid item xs={12} md={4} className="pr-3">
            <Typography variant="body1" className="fw-600" sx={{ pb: 2 }}>User in Other Agency</Typography>
            <Typography variant="body2" className="fw-500" color={"text.greyText4"}>The above user might be a member in other agency as client, user & admin. Here we can change the user role</Typography>
          </Grid>
          {workspace?.length > 0 ? <>
            <Grid item xs={12} md={8}>

              <Grid container justifyContent={"space-between"} alignItems={"center"} className="otherAgenciesHeader">
                <Typography variant="body2" className="fw-500 uppercase" color="text.greyText5">Agency Name</Typography>
                <Typography variant="body2" className="fw-500 uppercase" color="text.greyText5">USer Role</Typography>
              </Grid>

              {
                workspace.slice(0, workspaceCount).map((item) => {
                  const userType = role[item.id]?.type
                  return (
                    <Grid container justifyContent={"space-between"} alignItems={"center"} className="otherAgenciesItem">
                      <Grid className="dflex aCenter">

                        <Avatar src={item.agency_logo} sx={{ width: 40, height: 40 }} />

                        <Grid className="pl-1">
                          <Typography variant="body2" className="fw-600">{item.agency_name}</Typography>
                          <Typography variant="body2" color={"text.greyText4"} className="dflex mt5 fs-10"><BuildingIcon /><span className="ml-1">{item.category}</span></Typography>
                        </Grid>
                      </Grid>
                      <Typography variant="body2" className="fs-12 500 dflex" color={"text.greyText1"}><UserIcon /><span className="ml-1">{userType}</span></Typography>
                    </Grid>
                  )
                })
              }
              {workspace.length - 1 >= 6 ? <Typography className="jEnd dflex primary1 cursorPointer" onClick={() => { workspaceCount === 6 ? setWorkspaceCount(workspace?.length) : setWorkspaceCount(6) }} > {workspaceCount === 6 ? `Show more (${workspace?.length - 6})` : "Show Less"}</Typography>
                : ""}

            </Grid>
          </> : <Grid className="dflex aCenter"><Typography>User is not in other agencies</Typography></Grid>}
          <DeleteConfirmation
        open={openDelete}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
        title=""
        content="Are you sure you want delete the selected agency ?"
        contentStyle={{ color: '#64748B' }}
      />
        </Grid>
      </Grid>
    </Grid>
  );
};

// default props
UserInfo.defaultProps = {
  classes: {}
};

// prop types
UserInfo.propTypes = {
  classes: PropTypes.object
};


export default styled(UserInfo)(Style);
