import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAlert, setLoading } from "../reducers/uiReducer";
import { parseErrorResponses } from "../../utils/responseParser";
import { planService } from "../services/planService";

export const fetchPlanList = createAsyncThunk("agencies/planList",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        const response = await planService.planList({agency_id:param})
     
        return response?.data;
      } catch (err) {
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const upgradePlan = createAsyncThunk("agencies/updatePlan",
    async ({cb,...param}, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Updating plan..."));
        const response = await planService.updatePlan(param)
        dispatch(setLoading(null));
        dispatch(cb(param?.agency_id))
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )

  export const planMembers = createAsyncThunk("agencies/planmembers",
    async (param, { rejectWithValue, dispatch }) => {
      try {
        dispatch(setLoading("Fetching PlanMembers..."));
        const response = await planService.planMembers(param)
        dispatch(setLoading(null));
        return response?.data;
      } catch (err) {
        dispatch(setLoading(null));
        parseErrorResponses(err?.response || err, dispatch);
        return rejectWithValue(err);
      }
    }
  )