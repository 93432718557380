import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, Typography, Grid } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Style from "./style";
import moment from "moment";

const TrialDyas = ({ setTrialDate, setTrialDays, setTrialPeriod }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [daysDifference, setDaysDifference] = useState("");
  const classes = Style();
  const [trialdays, settrialdays] = useState(false);
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setTrialDate(newDate);
  };
 
  useEffect(() => {
    if (setTrialPeriod) {
      const trialEndDate = moment().add(setTrialPeriod, "days"); 
      setSelectedDate(trialEndDate.toDate());
    }
  }, [setTrialPeriod]);

  useEffect(() => {
    if (selectedDate) {

      const currentDate = new Date();
      const selectedDateCopy = new Date(selectedDate);
      selectedDateCopy.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      const timeDifference = selectedDateCopy - currentDate;
      const dayDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
      setDaysDifference(dayDifference);
      setTrialDays(dayDifference)
      settrialdays(true);
    } else {
      setDaysDifference("");
      settrialdays(false)
    }
  }, [selectedDate]);

  return (
    <Grid container sx={{ display: "flex", justifyContent: "space-between", width: "fit-content", gap: "30px" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={selectedDate}
          onChange={handleDateChange}
          sx={classes.datePicker}
          slotProps={{ textField: { placeholder: '' } }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              className="textFieldDatePicker"
              size="small"
              value={daysDifference !== "" ? daysDifference : params.inputProps.value}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
            />

          )}
        />
        {trialdays ? (
          <>
            <Typography sx={classes[".textforms"]}> Trial extended for {daysDifference + " "}days</Typography>
          </>
        ) : <>
        </>}
        {/* <Typography>Extended for {daysDifference +" "}days</Typography> */}
      </LocalizationProvider>
    </Grid>
  );
};

export default TrialDyas;
