import React from "react";
import { ProtectedRoute } from "../hoc/ProtectedRoute";
import AgencyList from "../containers/Agencies/AgencyList";
import AgencyDetail from "../containers/Agencies/AgencyDetail";
import AgencyDashboard from "../containers/Agencies";
import UserInfo from "../containers/Agencies/UserInfo";
import AgecyUsers from "../containers/Agencies/Users";
//import AgencyLayout from "../layouts/AgencyLayout"; // Make sure to adjust the path based on your project structure
import CombinedLayout from "../layouts/AgencyLayout";
import { element } from "prop-types";
import PlanChange from "../containers/Agencies/PlanChange";

const agenciesRoutes = [
    {
        path: '/agencies',
        element: <ProtectedRoute />,
        children: [
            {
                element: <CombinedLayout />, // Wrap all agency routes with the AgencyLayout
                children: [
                    {
                        path: '',
                        element: <AgencyDashboard />
                    },
                    {
                        path: 'list',
                        element: <AgencyList />
                    },
                    {
                        path: 'edit/:agency_id',
                        element: <AgencyDetail />
                    },
                    {
                        path: 'users',
                        element: <AgecyUsers />
                    },
                    {
                        path: 'userinfo/:id',
                        element: <UserInfo />
                    },
                    {
                        path:'changeplan/:agency_id',
                        element:<PlanChange/>
                    }
                ]
            }
        ]
    }
];

export default agenciesRoutes;